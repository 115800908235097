import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react'
import type { SearchResponse } from '@algolia/client-search'

import { useDispatch, useSelect } from 'store/index'
import {
  clearMoreFilters,
  setShouldApplyFilters,
  backgroundSearch,
} from 'store/search'

import { setDesktopFilterOpen } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import ClickableFilters from 'components/Search/ClickableFilters'

import style from './MoreFilters.module.scss'

import { useExpandedFilter } from '../useExpandedFilter'
import Amenities from '../Amenities/Amenities'
import useClickOutSideFilters from '../useClickOutSideFilters'

import urlToSearchState from 'utils/search/urlToSearchState'

import CaretIcon from 'assets/icons/icon-caret.svg'

type MoreFiltersProps = {
  cachedFacets: SearchResponse['facets']
}

const MoreFilters: React.FC<MoreFiltersProps> = ({ cachedFacets }) => {
  const router = useAugmentedRouter()

  const [filterOpen, setFilterOpen] = useState(false)

  const numResults = useSelect((state) => state.search.numResults)
  const moreFiltersRefined = useSelect(
    (state) => state.search.moreFiltersRefined,
  )
  const searchFiltersMounted = useSelect(
    (state) => state.search.searchFiltersMounted,
  )

  const {
    selectedLocationAmenities,
    selectedViewAmenities,
    selectedAccessibility,
  } = useSelect((state) => state.search)

  const moreSelectedFilters = selectedLocationAmenities.concat(
    selectedViewAmenities,
    selectedAccessibility,
  )

  const hasMoreFiltersRefined = useMemo(() => {
    const { refinementList } = urlToSearchState(router.asPath)
    return (
      moreFiltersRefined ??
      (refinementList &&
        (refinementList['amenities.Location'].length ||
          refinementList['amenities.View'].length ||
          refinementList['amenities.Accessibility'].length))
    )
  }, [moreFiltersRefined, router.asPath])

  const elRef = useRef<HTMLDivElement>(null)

  const appDispatch = useDispatch()

  const handleApply = useCallback(
    (maintainDesktopFilter: boolean) => {
      setFilterOpen(false)
      if (!maintainDesktopFilter) {
        appDispatch(setDesktopFilterOpen(false))
      }
      window.scrollTo(0, 0)
      document.body.classList.remove('noScroll', 'fullWidth')
      appDispatch(setShouldApplyFilters(true))
    },
    [appDispatch],
  )

  const handleClear = () => {
    appDispatch(clearMoreFilters())
    appDispatch(backgroundSearch())
  }

  useClickOutSideFilters({
    ref: elRef,
    isOpen: filterOpen,
    onClickOutSide: handleApply,
  })

  const { selectedFiltersLength, expandedFilterStyle } = useExpandedFilter(
    moreSelectedFilters.length,
    style,
  )

  return (
    <div className={`${style.select} ${expandedFilterStyle}`} ref={elRef}>
      <div
        aria-hidden="true"
        className={`${style.value} ${
          filterOpen ? style.focused : ''
        } filter-category-button`}
        data-testid="moreFiltersBtn"
        onClick={() => {
          if (filterOpen) {
            document.body.classList.remove('noScroll', 'fullWidth')
            appDispatch(setDesktopFilterOpen(false))
          } else {
            document.body.classList.add('noScroll', 'fullWidth')
            appDispatch(setDesktopFilterOpen(true))
          }
          setFilterOpen((filterOpen) => !filterOpen)
        }}
      >
        <strong>More Filters {selectedFiltersLength}</strong>
        <CaretIcon
          className={`${style.caret} ${filterOpen ? style.focused : ''}`}
          height="7px"
          width="12px"
        />
      </div>
      <div className={`${style.options} ${filterOpen ? style.focused : ''}`}>
        <div>
          <div className={style.amenities}>
            {filterOpen && (
              <ClickableFilters attribute="amenities.MoreFilters" />
            )}

            <Amenities
              attribute="amenities.Location"
              cachedFacets={cachedFacets?.['amenities.Location']}
              displayLimit={10}
              limit={300}
              operator={'or'}
              showMoreLimit={300}
            />

            <Amenities
              attribute="amenities.View"
              cachedFacets={cachedFacets?.['amenities.View']}
              displayLimit={10}
              limit={300}
              operator={'or'}
              showMoreLimit={300}
            />
            <Amenities
              attribute="amenities.Accessibility"
              cachedFacets={cachedFacets?.['amenities.Accessibility']}
              displayLimit={10}
              limit={300}
              operator={'and'}
              showMoreLimit={300}
            />
          </div>
          {/**
           // Hide Activities until we want to use them
          <div>
            <Amenities
              attribute="amenities.Area Activities"
              operator={'and'}
              limit={300}
              displayLimit={10}
              showMoreLimit={300}
              cachedFacets={cachedFacets?.['amenities.Area Activities']}
            />
          </div>
          */}
          <footer className={`${style.footer} ${style.shadowed}`}>
            <button
              className={`${style.btn} ${style.clearBtn}`}
              disabled={searchFiltersMounted && !hasMoreFiltersRefined}
              onClick={() => {
                handleClear()
              }}
            >
              Clear
            </button>
            <button
              className={`${style.btn} ${style.submitBtn}`}
              disabled={!numResults}
              onClick={() => {
                handleApply(false)
              }}
            >
              {`View ${numResults} ${numResults === 1 ? 'Result' : 'Results'}`}
            </button>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default MoreFilters
