import React, { ReactNode } from 'react'
import { connectPagination } from 'react-instantsearch-dom'

import style from './Mobile-Map-Pagination.module.scss'

// Components
import CustomLink from '../Link/CustomLink'

// Icons
import PreviousIcon from 'assets/icons/icon-paginationMapPrevious.svg'
import NextIcon from 'assets/icons/icon-paginationMapNext.svg'

const MobileMapPagination = connectPagination(
  ({ currentRefinement, nbPages, refine, createURL, direction }) => {
    return (
      <div
        className={
          (direction === 'left' && currentRefinement === 1) ||
          (direction === 'right' && currentRefinement === nbPages)
            ? style.mobilePaginationHidden
            : style.mobilePagination
        }
      >
        {direction === 'left' && currentRefinement !== 1 ? (
          <CustomLink
            href={createURL(currentRefinement - 1)}
            onClick={(e) => {
              e.preventDefault()
              refine(currentRefinement - 1)
            }}
            title={`Page ${currentRefinement - 1}`}
          >
            <PreviousIcon />
          </CustomLink>
        ) : direction === 'right' && currentRefinement !== nbPages ? (
          <CustomLink
            href={createURL(currentRefinement + 1)}
            onClick={(e) => {
              e.preventDefault()
              refine(currentRefinement + 1)
            }}
            title={`Page ${currentRefinement + 1}`}
          >
            <NextIcon />
          </CustomLink>
        ) : null}
      </div>
    )
  },
) as unknown as React.FC<any>

export default MobileMapPagination
