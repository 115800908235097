import React, { useEffect, useMemo } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import type { StateResultsProvided } from 'react-instantsearch-core'

import { useDispatch, useSelect } from 'store/index'
import { setIsSearching, setNumHits, setPage, setLatLng } from 'store/search'

export type AlgoliaStateResultsProps = Partial<StateResultsProvided> & {
  error: string
}

const AlgoliaStateResults = ({
  searchResults,
  isSearchStalled,
  error,
}: AlgoliaStateResultsProps) => {
  const appDispatch = useDispatch()
  const page = useSelect((state) => state.search.page)
  const searchPage = useMemo(() => searchResults?.page, [searchResults?.page])

  useEffect(() => {
    isSearchStalled === false &&
      (searchResults?.nbHits !== undefined || error === 'ZERO_RESULTS') &&
      appDispatch(setIsSearching(false))
    if (error === 'ZERO_RESULTS') {
      appDispatch(setNumHits(0))
      appDispatch(
        setLatLng({
          lat: 37.09024,
          lng: -95.712891,
        }),
      )
    }
  }, [isSearchStalled, searchResults?.nbHits, appDispatch, error])

  useEffect(() => {
    //searchPage may be 0
    searchPage !== undefined &&
      searchPage !== page &&
      appDispatch(setPage(searchPage))
  }, [appDispatch, page, searchPage])

  return <></>
}

export default connectStateResults(AlgoliaStateResults)
