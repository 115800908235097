import React from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import type { CurrentRefinementsProvided } from 'react-instantsearch-core'

import { useDispatch, useSelect } from 'store/index'
import { clearAllFilters, backgroundSearch } from 'store/search'
import { selectedFiltersNames } from 'store/search/search.selectors'

import useAnalytics from 'hooks/useAnalytics'

type CurrentRefinementsProps = {
  className: string
  children?: React.ReactNode
} & CurrentRefinementsProvided
const ClearRefinements = connectCurrentRefinements<CurrentRefinementsProps>(
  ({ className, items, children }) => {
    const appDispatch = useDispatch()
    const searchFiltered = useSelect((state) => state.search.searchFiltered)
    const searchFiltersMounted = useSelect(
      (state) => state.search.searchFiltersMounted,
    )
    const { clearSearchClicked } = useAnalytics()
    const selectedFilterNames = useSelect(selectedFiltersNames)

    return (
      <button
        className={className}
        disabled={
          searchFiltersMounted && !items.length && !searchFiltered
            ? true
            : false
        }
        onClick={() => {
          clearSearchClicked({
            ...selectedFilterNames,
            initializedFrom: 'Filters Modal',
            clickedFrom: 'Clear Filters',
          })
          appDispatch(clearAllFilters())
          appDispatch(backgroundSearch())
        }}
      >
        {children}
      </button>
    )
  },
)
export default ClearRefinements
