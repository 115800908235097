import React, { useRef, useCallback, useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { useSelect, useDispatch } from 'store/index'

import { setShowLoginPromptModal } from 'reducers/uiState'
import { setFirstFavoriteId } from 'reducers/favorites'

import style from './FavoritesLogin.module.scss'
import FavoritesLoginPrompt from './FavoritesLoginPrompt'

import CloseIcon from 'assets/icons/icon-close.svg'

const FavoritesLoginModal: React.FC = () => {
  // Redux actions
  const appDispatch = useDispatch()

  // Redux selectors
  const loginPromptModalOpen = useSelect(
    (state) => state.uiState.loginPromptModalOpen,
  )

  const modalRef = useRef<HTMLDivElement>(null)
  const modalBodyRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const oldRef = modalRef.current

    if (loginPromptModalOpen && modalRef.current) {
      disableBodyScroll(modalRef.current)
    }

    return () => {
      if (loginPromptModalOpen && oldRef) {
        enableBodyScroll(oldRef)
      }
    }
  }, [loginPromptModalOpen])

  const closeModal = useCallback(() => {
    if (modalRef.current) {
      modalRef.current.classList.add(style.fadeOut)
      enableBodyScroll(modalRef.current)
    }

    appDispatch(setFirstFavoriteId(''))
    appDispatch(setShowLoginPromptModal(false))
  }, [appDispatch])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if click is inside modalRef and not inside modalBodyRef to handle map view clicks
      if (
        loginPromptModalOpen &&
        modalRef.current &&
        modalRef.current.contains(e.target as Node)
      ) {
        closeModal()
      }
    }
    document.addEventListener('click', handleClickOutside, false)

    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [loginPromptModalOpen, appDispatch, closeModal])

  return (
    <div className={`${style.loginPromptModalWrapper}`} ref={modalRef}>
      <div className={`${style.loginPromptModal} ${style.fadeIn}`}>
        <div className={style.loginPromptModalBody} ref={modalBodyRef}>
          <div className={style.loginPromptModalTop}>
            <button
              className={`${style.modalClose} ${style.responsiveCloseIcon}`}
              onClick={() => closeModal()}
            >
              <CloseIcon width={11} />
            </button>
          </div>
          <FavoritesLoginPrompt isModal={true} />
        </div>
      </div>
    </div>
  )
}

export default FavoritesLoginModal
