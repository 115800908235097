import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { connectGeoSearch } from 'react-instantsearch-dom'
import { GeoSearchProvided, NESW } from 'react-instantsearch-core'
import { GoogleMap } from '@react-google-maps/api'

import { useSelect } from 'store/index'

import { AlgoliaListing } from 'types/externalData'

// Functions
function isEqualPosition(
  a: google.maps.LatLngLiteral,
  b: google.maps.LatLngLiteral,
) {
  if (a === b) {
    return true
  }
  if (a === undefined || b === undefined) {
    return false
  }

  return a.lat === b.lat && a.lng === b.lng
}

function isEqualCurrentRefinement(a: NESW, b: NESW) {
  if (a === b) {
    return true
  }

  if (a === undefined || b === undefined) {
    return false
  }

  return (
    isEqualPosition(a.northEast, b.northEast) &&
    isEqualPosition(a.southWest, b.southWest)
  )
}

// Function to offset duplicate coordinates, making all map markers visible
const dedupeHits = (hits: AlgoliaListing[]) => {
  const hitMap: Record<string, number> = {}

  const dedupedHits: typeof hits = hits.map((hit) => {
    if (JSON.stringify(hit._geoloc) in hitMap) {
      const dedupedHit: typeof hit = {
        ...hit,
        _geoloc: {
          ...hit._geoloc,
          lat: hit._geoloc.lat - 0.00002 * hitMap[JSON.stringify(hit._geoloc)],
        },
      }
      hitMap[JSON.stringify(dedupedHit._geoloc)] = 1
      hitMap[JSON.stringify(hit._geoloc)]++
      return dedupedHit
    } else {
      hitMap[JSON.stringify(hit._geoloc)] = 1
      return hit
    }
  })

  return dedupedHits
}

const mapContainerStyle = {
  height: '100%',
}

const mapOptions: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  clickableIcons: false,
  gestureHandling: 'greedy',
  zoomControlOptions: {
    position: 5,
  },
  maxZoom: 18,
}

type GeoSearchProps = {
  mapInstanceRef: React.MutableRefObject<google.maps.Map<HTMLDivElement> | null>
  mapRef: React.RefObject<HTMLDivElement>
  initialZoom: number
  initialPosition: google.maps.LatLngLiteral
  children: (...args: any[]) => any
} & GeoSearchProvided<AlgoliaListing> &
  google.maps.MapOptions

const CustomGeoSearch: React.FC<GeoSearchProps> = ({
  hits,
  position,
  currentRefinement,
  refine,
  children,
  initialZoom = 18,
  initialPosition,
}) => {
  const [state, setState] = useState({
    hasMapMoveSinceLastRefine: false,
    previousPosition: position,
    previousCurrentRefinement: currentRefinement,
    isMapReady: false,
  })

  const [latestHits, setLatestHits] = useState(hits)

  const isPendingRefine = useRef(false)
  const mapInstanceRef = useRef<google.maps.Map | null>(null)
  const isUserInteraction = useRef(true)
  const isInitialized = useRef(false)

  const isSearching = useSelect((state) => state.search.isSearching)

  const shouldUpdate = useMemo(
    () =>
      !isPendingRefine.current &&
      !state.hasMapMoveSinceLastRefine &&
      !isSearching,
    [state.hasMapMoveSinceLastRefine, isSearching],
  )

  const markers = useMemo(
    () => children({ hits: dedupeHits(latestHits) }),
    [children, latestHits],
  )

  const boundingBoxFromHits = useMemo(() => {
    const createBoundingBoxFromHits = (hits: AlgoliaListing[]) => {
      const latLngBounds = hits.reduce(
        (acc, hit) => acc.extend(hit._geoloc),
        new window.google.maps.LatLngBounds(),
      )

      return {
        northEast: latLngBounds.getNorthEast().toJSON(),
        southWest: latLngBounds.getSouthWest().toJSON(),
      }
    }

    return !currentRefinement && latestHits.length
      ? createBoundingBoxFromHits(latestHits)
      : currentRefinement
  }, [currentRefinement, latestHits])

  const boundingBoxPadding = useMemo(
    () => (!currentRefinement ? undefined : 0),
    [currentRefinement],
  )

  const handleMapLoad = useCallback((map: google.maps.Map) => {
    mapInstanceRef.current = map
  }, [])

  const onIdle = useCallback(() => {
    if (mapInstanceRef.current) isInitialized.current = true
    if (
      isUserInteraction.current &&
      mapInstanceRef.current &&
      isPendingRefine.current
    ) {
      isPendingRefine.current = false
      const bounds = mapInstanceRef.current?.getBounds()
      if (bounds) {
        refine({
          northEast: bounds.getNorthEast().toJSON(),
          southWest: bounds.getSouthWest().toJSON(),
        })
      }
    }
  }, [refine])

  const handleChange = useCallback(() => {
    if (isUserInteraction.current && isInitialized.current) {
      setState((state) => ({
        ...state,
        hasMapMoveSinceLastRefine: true,
      }))

      isPendingRefine.current = true
    }
  }, [])

  useEffect(() => {
    const lockUserInteraction = (
      functionThatAltersMapPostition: () => void,
    ) => {
      isUserInteraction.current = false
      functionThatAltersMapPostition()
      isUserInteraction.current = true
    }

    if (shouldUpdate) {
      if (boundingBoxFromHits) {
        lockUserInteraction(() => {
          const oldBounds = mapInstanceRef.current?.getBounds()
          const newBounds = new window.google.maps.LatLngBounds(
            boundingBoxFromHits.southWest,
            boundingBoxFromHits.northEast,
          )

          if (!oldBounds?.equals(newBounds)) {
            mapInstanceRef.current?.fitBounds(newBounds, boundingBoxPadding)
          }
        })
      } else {
        lockUserInteraction(() => {
          mapInstanceRef.current?.setZoom(initialZoom)
          mapInstanceRef.current?.setCenter(initialPosition)
        })
      }
    }
  }, [
    boundingBoxFromHits,
    boundingBoxPadding,
    initialPosition,
    initialZoom,
    shouldUpdate,
  ])

  useEffect(() => {
    const positionChanged = !isEqualPosition(state.previousPosition, position)
    const currentRefinementChanged = !isEqualCurrentRefinement(
      state.previousCurrentRefinement,
      currentRefinement,
    )

    if (positionChanged || currentRefinementChanged) {
      setState((state) => ({
        ...state,
        previousPosition: position,
        previousCurrentRefinement: currentRefinement,
        hasMapMoveSinceLastRefine: false,
      }))
    }
  }, [
    currentRefinement,
    position,
    state.previousCurrentRefinement,
    state.previousPosition,
  ])

  useEffect(() => {
    if (!isSearching) {
      import('lodash/isEqual').then(({ default: isEqual }) => {
        if (!isEqual(latestHits, hits)) {
          setLatestHits(hits)
        }
      })
    }
  }, [hits, isSearching, latestHits])

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      onCenterChanged={handleChange}
      onDragStart={handleChange}
      onIdle={onIdle}
      onLoad={handleMapLoad}
      onZoomChanged={handleChange}
      options={mapOptions}
      zoom={initialZoom}
    >
      {React.Children.map(markers, (child) => {
        return React.cloneElement(child as any, {
          googleMapsInstance: mapInstanceRef.current,
        })
      })}
    </GoogleMap>
  )
}

export default connectGeoSearch(React.memo(CustomGeoSearch))
