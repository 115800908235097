import React, { useCallback } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import uniqBy from 'lodash/uniqBy'
import type {
  RefinementListExposed,
  RefinementListProvided,
} from 'react-instantsearch-core'

import { useSelect, useDispatch } from 'store/index'
import {
  filterAmenities,
  filterLocationAmenities,
  filterViewAmenities,
  filterAccessibility,
  filterActivities,
  filterPropertyTypes,
} from 'store/search'
import { getAccessibilitesByPetsAllowed } from 'store/search/search.utils'

import {
  setShowMoreActivities,
  setShowMoreAmenities,
  setShowMoreLocationAmenities,
  setShowMorePropertyTypes,
  setShowMoreAccessibility,
  setShowMoreViewAmenities,
} from 'reducers/uiState'

import Checkbox from 'components/Forms/Checkbox'

import { DesktopBreakpoint } from 'config/Breakpoints'
import { mostPopularAmenities } from 'config/Search'

type AmenitiesProps = {
  cachedFacets: Record<string, number> | undefined
  displayLimit: number
} & RefinementListProvided &
  RefinementListExposed

const Amenities: React.FC<AmenitiesProps> = ({
  cachedFacets,
  displayLimit,
  attribute,
  items,
}) => {
  // TODO: properly set Property Amenities name
  const width = useSelect((state) => state.uiState.width)
  const groupName =
    attribute === 'amenities.Amenities'
      ? 'Additional Amenities'
      : attribute === 'amenities.Location'
      ? 'Location'
      : attribute === 'amenities.View'
      ? 'Views'
      : attribute === 'amenities.Accessibility'
      ? 'Accessibility'
      : attribute === 'amenities.Area Activities'
      ? 'Area Activities'
      : attribute === 'Property Type' && width < DesktopBreakpoint
      ? 'Property Type'
      : ''

  // State
  const values = cachedFacets
    ? uniqBy(
        [
          ...items,
          ...Object.keys(cachedFacets).map((key) => ({
            label: key,
            value: [key],
            count: cachedFacets[key],
            isRefined: false,
          })),
        ],
        'label',
      )
    : items

  const selectedAmenities = useSelect((state) => {
    switch (attribute) {
      case 'amenities.Amenities':
        return state.search.selectedAmenities
      case 'amenities.Location':
        return state.search.selectedLocationAmenities
      case 'amenities.View':
        return state.search.selectedViewAmenities
      case 'amenities.Accessibility':
        return state.search.selectedAccessibility
      case 'amenities.Area Activities':
        return state.search.selectedActivities
      case 'Property Type':
        return state.search.selectedPropertyTypes
      default:
        return []
    }
  })
  const showMoreAmenities = useSelect((state) => {
    switch (attribute) {
      case 'amenities.Amenities':
        return state.uiState.showMoreAmenities
      case 'amenities.Location':
        return state.uiState.showMoreLocationAmenities
      case 'amenities.View':
        return state.uiState.showMoreViewAmenities
      case 'amenities.Accessibility':
        return state.uiState.showMoreAccessibility
      case 'amenities.Area Activities':
        return state.uiState.showMoreActivites
      case 'Property Type':
        return state.uiState.showMorePropertyTypes
      default:
        return false
    }
  })

  // Actions
  const appDispatch = useDispatch()

  // Functions
  const handleChange = useCallback(
    (value: any) => {
      let newSelections = selectedAmenities

      if (selectedAmenities.includes(value)) {
        newSelections = selectedAmenities.filter((amenity) => amenity !== value)
      } else {
        newSelections = [...selectedAmenities, value]
      }

      switch (attribute) {
        case 'amenities.Amenities':
          appDispatch(filterAmenities(newSelections))
          break
        case 'amenities.Location':
          appDispatch(filterLocationAmenities(newSelections))
          break
        case 'amenities.View':
          appDispatch(filterViewAmenities(newSelections))
          break
        case 'amenities.Accessibility': {
          const accessibilties = getAccessibilitesByPetsAllowed({
            accessibilities: newSelections,
            isPetAllowed: value,
          })
          appDispatch(filterAccessibility(accessibilties))
          break
        }
        case 'amenities.Area Activities':
          appDispatch(filterActivities(newSelections))
          break
        case 'Property Type':
          appDispatch(filterPropertyTypes(newSelections))
          break
        default:
          return false
      }
    },
    [appDispatch, attribute, selectedAmenities],
  )

  const handleShow = (shouldShowMore: boolean) => {
    switch (attribute) {
      case 'amenities.Amenities':
        appDispatch(setShowMoreAmenities(shouldShowMore))
        break
      case 'amenities.Location':
        appDispatch(setShowMoreLocationAmenities(shouldShowMore))
        break
      case 'amenities.View':
        appDispatch(setShowMoreViewAmenities(shouldShowMore))
        break
      case 'amenities.Accessibility':
        appDispatch(setShowMoreAccessibility(shouldShowMore))
        break
      case 'amenities.Area Activities':
        appDispatch(setShowMoreActivities(shouldShowMore))
        break
      case 'Property Type':
        appDispatch(setShowMorePropertyTypes(shouldShowMore))
        break
      default:
        break
    }
  }

  const renderShowText = (attribute: string) => {
    const showText = showMoreAmenities ? 'Less' : 'More'
    switch (attribute) {
      case 'amenities.Amenities':
        return `${showText} Amenities`
      case 'amenities.Location':
        return `${showText} Locations`
      case 'amenities.View':
        return `${showText} Views`
      case 'amenities.Accessibility':
        return `${showText} Accessibility`
      case 'amenities.Area Activities':
        return `${showText} Area Activities`
      case 'Property Type':
        return `${showText} Property Types`
      default:
        return ''
    }
  }

  return values.length ? (
    <div>
      {attribute === 'amenities.Amenities' && (
        <>
          <h3>Most Popular Amenities</h3>
          <div>
            {values
              .filter((value) => mostPopularAmenities.includes(value.label))

              .map((amenity) => (
                <Checkbox
                  checked={selectedAmenities.includes(amenity.label)}
                  disabled={false}
                  id={`${attribute}-${amenity.label}`}
                  key={`${attribute}-${amenity.label}`}
                  label={amenity.label}
                  onChange={handleChange}
                  value={amenity.label}
                ></Checkbox>
              ))}
          </div>
        </>
      )}
      <h3>{groupName}</h3>
      <div>
        {values
          .filter((facet) => !mostPopularAmenities.includes(facet.label))
          .slice(0, showMoreAmenities ? values.length : displayLimit)
          .map((amenity) => (
            <Checkbox
              checked={selectedAmenities.includes(amenity.label)}
              disabled={false}
              id={`${attribute}-${amenity.label}`}
              key={`${attribute}-${amenity.label}`}
              label={amenity.label}
              onChange={handleChange}
              value={amenity.label}
            ></Checkbox>
          ))}
      </div>
      {values.length > displayLimit ? (
        <button
          className="btn-viewMore"
          onClick={() => handleShow(!showMoreAmenities)}
        >
          {`View ${renderShowText(attribute)}`}
        </button>
      ) : null}
    </div>
  ) : null
}

export default connectRefinementList(Amenities)
