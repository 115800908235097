import React, { useRef } from 'react'
import type { SearchResponse } from '@algolia/client-search'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

import { useDispatch, useSelect } from 'store/index'
import { setShouldApplyFilters } from 'store/search'
import { searchSelectors } from 'store/search'

import { setMobileFiltersOpen } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import style from './FilterBar.module.scss'
import Price from './Filters/Price/Price'
import Amenities from './Filters/Amenities/Amenities'
import PropertyType from './Filters/PropertyType/PropertyType'
import MoreFilters from './Filters/MoreFilters/MoreFilters'
import ClearRefinements from './Filters/ClearRefinements'
import AmenitiesGroup from './Filters/AmentiesGroup/AmenitiesGroup'
import BedsAndBath from './Filters/BedsAndBath/BedsAndBath'
import MinRangePicker from './Filters/MinRangePicker/MinRangePicker'
import ResetFiltersButtons from './ResetFilterButtons/ResetFiltersButtons'
import ClickableFilters from './ClickableFilters'

import { DesktopBreakpoint } from 'config/Breakpoints'

import CloseIcon from 'assets/icons/icon-close.svg'

type FilterBarProps = {
  cachedFacets: SearchResponse['facets']
  cachedFacetsStats: SearchResponse['facets_stats']
}

const FilterBar: React.FC<FilterBarProps> = ({
  cachedFacets,
  cachedFacetsStats,
}) => {
  const width = useSelect((state) => state.uiState.width)
  const { pathname } = useAugmentedRouter()

  // State
  const mobileFilterRef = useRef<HTMLDivElement>(null)
  const mobileFiltersOpen = useSelect(
    (state) => state.uiState.mobileFiltersOpen,
  )
  const numResults = useSelect((state) => state.search.numResults)
  const filtersCount = useSelect(searchSelectors.getSelectedFiltersCount)

  // Actions
  const appDispatch = useDispatch()

  const openMobileFilters = () => {
    appDispatch(setMobileFiltersOpen(true))

    if (width <= DesktopBreakpoint && mobileFilterRef.current) {
      disableBodyScroll(mobileFilterRef.current)
    }
  }

  const closeMobileFilters = () => {
    appDispatch(setMobileFiltersOpen(false))
    appDispatch(setShouldApplyFilters(true))

    if (width <= DesktopBreakpoint && mobileFilterRef.current) {
      enableBodyScroll(mobileFilterRef.current)
    }
  }

  return (
    <div className={style.filterBarWrapper}>
      <>
        <div className={`${style.filterBar} ${style.filterBarDesktop}`}>
          <BedsAndBath />
          <Price attribute="Average Per Night" />
          <PropertyType cachedFacets={cachedFacets} />
          <AmenitiesGroup cachedFacets={cachedFacets} />
          <MoreFilters cachedFacets={cachedFacets} />
          <ResetFiltersButtons>
            {!!filtersCount && (
              <ResetFiltersButtons.Filters initializedFrom="Search Navigation" />
            )}
          </ResetFiltersButtons>
        </div>

        <div className={`${style.filterBar} ${style.filterBarMobile}`}>
          <button
            className={style.mobileFilterTrigger}
            data-testid="mobileFilter"
            onClick={() => openMobileFilters()}
          >
            {pathname.includes('search') ? 'Filters' : 'Filter'}{' '}
            {!!filtersCount && (
              <>
                (<b>{filtersCount}</b>)
              </>
            )}
          </button>
          <div
            className={`${style.mobileFilters} ${
              mobileFiltersOpen ? style.mobileFiltersOpen : ''
            }`}
            ref={mobileFilterRef}
          >
            <header>
              <div>
                <ClearRefinements className={style.btn}>
                  Clear All
                </ClearRefinements>
              </div>
              <div>
                <span className={style.headerLabel}>Filters</span>
              </div>
              <div>
                <button
                  className={style.btn}
                  data-testid="closeFilters"
                  onClick={() => closeMobileFilters()}
                >
                  <CloseIcon width={11} />
                </button>
              </div>
            </header>
            {mobileFiltersOpen && <ClickableFilters />}
            <Price attribute="Average Per Night" expanded />

            <div>
              <h3>Rooms</h3>
              <MinRangePicker
                attribute="Bedrooms"
                cachedFacetsStats={cachedFacetsStats?.['Bedrooms']}
              />
              <MinRangePicker
                attribute="Total Beds"
                cachedFacetsStats={cachedFacetsStats?.['Total Beds']}
              />
              <MinRangePicker
                attribute="Bathrooms"
                cachedFacetsStats={cachedFacetsStats?.['Bathrooms']}
              />
            </div>
            <Amenities
              attribute="amenities.Amenities"
              cachedFacets={cachedFacets?.['amenities.Amenities']}
              displayLimit={20}
              limit={300}
              operator={'and'}
              showMoreLimit={300}
            />
            <Amenities
              attribute="Property Type"
              cachedFacets={cachedFacets?.['Property Type']}
              displayLimit={10}
              limit={300}
              operator={'or'}
              showMoreLimit={300}
            />
            <Amenities
              attribute="amenities.Location"
              cachedFacets={cachedFacets?.['amenities.Location']}
              displayLimit={10}
              limit={300}
              operator={'or'}
              showMoreLimit={300}
            />
            <Amenities
              attribute="amenities.View"
              cachedFacets={cachedFacets?.['amenities.View']}
              displayLimit={10}
              limit={300}
              operator={'or'}
              showMoreLimit={300}
            />
            <Amenities
              attribute="amenities.Accessibility"
              cachedFacets={cachedFacets?.['amenities.Accessibility']}
              displayLimit={10}
              limit={300}
              operator={'and'}
              showMoreLimit={300}
            />
            <Amenities
              attribute="amenities.Area Activities"
              cachedFacets={cachedFacets?.['amenities.Area Activities']}
              displayLimit={10}
              limit={300}
              operator={'and'}
              showMoreLimit={300}
            />
            <footer>
              <button
                className="btn-primary"
                data-testid="viewFilterResultsMbl"
                disabled={!numResults}
                onClick={() => closeMobileFilters()}
              >
                {`View ${numResults} ${
                  numResults === 1 ? 'Result' : 'Results'
                }`}
              </button>
            </footer>
          </div>
        </div>
      </>
    </div>
  )
}

export default FilterBar
