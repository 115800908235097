import { RefObject, useEffect, useCallback } from 'react'

type UseClickOutSideFiltersProps = {
  isOpen: boolean
  ref: RefObject<HTMLDivElement>
  onClickOutSide(maintainDesktopFilter: boolean): void
}

const useClickOutSideFilters = ({
  ref,
  onClickOutSide,
  isOpen,
}: UseClickOutSideFiltersProps) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        e.preventDefault()
        const maintainDesktopFilter = (
          e.target as HTMLElement
        ).classList.contains('filter-category-button')
        onClickOutSide(maintainDesktopFilter)
      }
    },
    [onClickOutSide, isOpen, ref],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [handleClickOutside])
}

export default useClickOutSideFilters
