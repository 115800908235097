export const getPointFromLatLng = (
  map: google.maps.Map,
  latlng: google.maps.LatLng,
) => {
  const projection = map.getProjection()
  const point = projection!.fromLatLngToPoint(latlng)
  return point
}

export const getInfowindowOffset = (
  map: google.maps.Map,
  marker: google.maps.Marker,
) => {
  const center = getPointFromLatLng(map, map.getCenter())
  const point = getPointFromLatLng(map, marker.getPosition()!)
  const southwest = getPointFromLatLng(map, map.getBounds()!.getSouthWest())
  const northeast = getPointFromLatLng(map, map.getBounds()!.getNorthEast())
  let quadrant = ''
  let offset: google.maps.Size | undefined = undefined

  quadrant += point.y > center.y ? 's' : 'n'
  quadrant += point.x < center.x ? 'w' : 'e'
  if (quadrant === 'ne' || quadrant === 'nw') {
    offset = new google.maps.Size(
      ((center.x - point.x) / Math.abs(southwest.x - northeast.x)) * 240,
      330,
    )
  } else if (quadrant === 'se' || quadrant === 'sw') {
    offset = new google.maps.Size(
      ((center.x - point.x) / Math.abs(southwest.x - northeast.x)) * 240,
      0,
    )
  }
  return offset
}
