import React from 'react'
import { connectHits } from 'react-instantsearch-dom'
import type { Hit, HitsProvided } from 'react-instantsearch-core'

import type { AlgoliaListing } from 'types/externalData'

type ComponentWithHitsType<T> = React.ComponentType<
  T & { hits: Hit<AlgoliaListing>[] }
>

// Pass Algolia hits as props to component
export default function withHits<
  T extends ComponentWithHitsType<React.ComponentProps<T>>,
>(Component: T) {
  const ComponentWithHits = connectHits<
    HitsProvided<AlgoliaListing> & React.ComponentProps<T>,
    AlgoliaListing
  >((props) => {
    // @ts-expect-error improved typing of `withHits`, but typing around this Component is tricky
    // one option is to move away from HOC and use `react-instantsearch-hooks` which ships with types
    // and should be easier to maintain the correct types for
    return <Component {...props} />
  })
  ComponentWithHits.displayName = 'ComponentWithHits'
  return ComponentWithHits
}
