import React, { useEffect } from 'react'
import { connectStats } from 'react-instantsearch-dom'

import { useDispatch } from 'store/index'
import { setNumHits } from 'store/search'

const Stats = connectStats(({ nbHits }) => {
  const appDispatch = useDispatch()

  useEffect(() => {
    appDispatch(setNumHits(nbHits))
  }, [nbHits, appDispatch])

  return (
    // Return empty framgent so that
    // above data can be put in context
    <></>
  )
})

export default Stats
