import React, { useEffect } from 'react'
import { connectPagination } from 'react-instantsearch-dom'

import { useSelect } from 'store/index'

import Pagination from './Pagination'

// Custom Pagination Component
// https://www.algolia.com/doc/api-reference/widgets/pagination/react/

type SearchPaginationProps = {
  currentRefinement: number
  nbPages: number
  refine: (...args: any[]) => void
  createURL: (...args: any[]) => string
  onPaginate: (...args: any[]) => void
}

const SearchPagination: React.FC<SearchPaginationProps> = ({
  currentRefinement: page,
  nbPages: totalPages,
  refine,
  createURL,
  onPaginate,
}) => {
  const numHits = useSelect((state) => state.search.numHits)

  useEffect(() => {
    if (page > totalPages) {
      refine(1)
    }
  }, [totalPages, page, refine])

  return (
    <Pagination
      createURL={createURL}
      itemsPerPage={20}
      onPaginate={onPaginate}
      page={page}
      refine={refine}
      totalItems={numHits || 0}
      totalPages={totalPages}
    />
  )
}

export default React.memo(connectPagination(SearchPagination))
