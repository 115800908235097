import React from 'react'

import { useDispatch } from 'store/index'
import {
  setSelectedAccessibility,
  setSelectedAmenities,
  setSelectedLocationAmenities,
  setSelectedPropertyTypes,
  setSelectedViewAmenities,
} from 'store/search'

import { ClickableFilterProps } from './ClickableFilters.types'
import style from './ClickableFilters.module.scss'

import CloseIcon from 'assets/icons/icon-close.svg'

const selectedAppliedFiltersMap = {
  setSelectedAccessibility,
  setSelectedAmenities,
  setSelectedLocationAmenities,
  setSelectedPropertyTypes,
  setSelectedViewAmenities,
}

export const ClickableFilter = ({
  selectedClickableFiltersMap,
  selectedFilter,
}: ClickableFilterProps) => {
  const appDispatch = useDispatch()

  const handleClick = () => {
    let reducerString = 'set'
    let selectedFilterFamily = []
    for (const key in selectedClickableFiltersMap) {
      const filters = selectedClickableFiltersMap[key]
      if (!filters.includes(selectedFilter)) continue

      reducerString += key.charAt(0).toUpperCase() + key.slice(1)
      selectedFilterFamily = filters
    }

    const updatingValues = selectedFilterFamily.filter(
      (clickedFilter) => selectedFilter !== clickedFilter,
    )

    const reducer = selectedAppliedFiltersMap[reducerString]
    if (reducer) appDispatch(reducer(updatingValues))
  }

  return (
    <button className={style.clickableFilterBtn} onClick={handleClick}>
      {selectedFilter}
      <CloseIcon height="12" width="12" />
    </button>
  )
}
