import React, { useRef, useEffect } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import dynamic from 'next/dynamic'
import type { HitsProvided } from 'react-instantsearch-core'

import { useSelect } from 'store/index'

import style from './Map-Hits.module.scss'

import type { AlgoliaListing } from 'types/externalData'

const MapResult = dynamic(() => import('./Map-Result'))

type MapHitsProps = {
  leftPagination: JSX.Element
  rightPagination: JSX.Element
} & HitsProvided<AlgoliaListing>

const MapHits = connectHits<MapHitsProps, AlgoliaListing>(
  ({ hits, leftPagination, rightPagination }) => {
    const mapHitsRef = useRef<HTMLDivElement>(null)
    const activeMarker = useSelect((state) => state.searchData.activeMarker)

    useEffect(() => {
      if (hits && activeMarker) {
        const index = hits.map(({ objectID }) => objectID).indexOf(activeMarker)
        if (mapHitsRef.current) {
          mapHitsRef.current.scrollLeft = 229 * index
        }
      }
    }, [activeMarker, hits])

    return (
      <div className={style.mapHits} ref={mapHitsRef}>
        {leftPagination}
        {hits?.length && activeMarker ? (
          hits.map((hit, i) => <MapResult hit={hit} key={i} />)
        ) : (
          <span>No Results</span>
        )}
        {rightPagination}
      </div>
    )
  },
)

export default MapHits
