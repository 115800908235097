import React from 'react'

import { useSelect } from 'store/index'

import MapHits from 'components/Search/Search-Results/MapHits'
import MobileMapPagination from 'components/Pagination/MobileMapPagination'

import style from './Map-Results-Grid.module.scss'

const MapResultsGrid: React.FC = () => {
  const activeMarker = useSelect((state) => state.searchData.activeMarker)
  const activeView = useSelect((state) => state.uiState.activeView)

  return (
    <div
      className={`${style.mapResultsGrid}
      ${activeView === 'map' && activeMarker ? style.active : style.hidden}`}
    >
      <MapHits
        leftPagination={<MobileMapPagination direction="left" />}
        rightPagination={<MobileMapPagination direction="right" />}
      />
    </div>
  )
}

export default MapResultsGrid
