import Script from 'next/script'

import { algoliaAppID, algoliaAppKey } from 'constants/globalEnv'

const AlgoliaAnalytics = () => {
  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: `var ALGOLIA_INSIGHTS_SRC = "https://cdn.jsdelivr.net/npm/search-insights@1.3.1";

!(function (e, a, t, n, s, i, c) {
  (e.AlgoliaAnalyticsObject = s),
    (e[s] =
      e[s] ||
      function () {
        (e[s].queue = e[s].queue || []).push(arguments);
      }),
    (i = a.createElement(t)),
    (c = a.getElementsByTagName(t)[0]),
    (i.async = 1),
    (i.src = n),
    c.parentNode.insertBefore(i, c);
})(window, document, "script", ALGOLIA_INSIGHTS_SRC, "aa");

// Initialize library
aa("init", {
  appId: "${algoliaAppID}",
  apiKey: "${algoliaAppKey}",
});`,
      }}
      id="search-insights"
      strategy="afterInteractive"
    />
  )
}

export default AlgoliaAnalytics
