import React, { useMemo } from 'react'

import styles from './CancellationPolicyOnSearch.module.scss'

import PaymentInfoIcon from 'assets/icons/icon-paymentInfo.svg'

type CancellationOnSearchProps = {}

const CancellationOnSearch: React.FC<CancellationOnSearchProps> = () => {
  return (
    <div className={styles.cancellationPolicyContainer}>
      <div className={styles.icon__wrapper}>
        <PaymentInfoIcon height={19} width={28} />
      </div>
      <span className={styles.cancellationPolicyText}>
        Need flexibility? Evolve provides flexibility for cancellations and
        refunds on most trip dates. See listing for more details
      </span>
    </div>
  )
}

export default CancellationOnSearch
