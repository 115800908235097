import React from 'react'

import { useSelect } from 'store/index'

import style from './IncrementDecrement.module.scss'

import PlusIcon from 'assets/icons/icon-plus.svg'
import MinusIcon from 'assets/icons/icon-minus.svg'

type IncrementDecrementProps = {
  value: number
  min: number
  max: number
  onChange: (value: number) => void
}

const IncrementDecrement: React.FC<IncrementDecrementProps> = ({
  value,
  min,
  max,
  onChange,
}) => {
  const searchFiltersMounted = useSelect(
    (state) => state.search.searchFiltersMounted,
  )
  return (
    <div className={style.control}>
      <button
        disabled={searchFiltersMounted && value <= min}
        onClick={() => onChange(value - 1)}
      >
        <MinusIcon height={34} width={34} />
      </button>
      <div>{value}+</div>
      <button disabled={value >= max} onClick={() => onChange(value + 1)}>
        <PlusIcon height={34} width={34} />
      </button>
    </div>
  )
}

export default IncrementDecrement
