import React, { useCallback } from 'react'
import dayjs from 'dayjs'

import { useDispatch, useSelect } from 'store/index'

import { setActiveView } from 'reducers/uiState'

import ABTest from 'components/ABTest'
import Overview from 'components/Pagination/Overview'
import BarLoader from 'components/Loader/BarLoader'

import style from './Results-Grid.module.scss'

import CancellationPolicyOnSearchGP341 from '../AB-GP341/CancellationPolicyOnSearch'
import CancellationPolicyOnSearchGP477 from '../AB-GP477/CancellationPolicyOnSearch'

import LocationIcon from 'assets/icons/location-white.svg'

export type ResultsGridProps = {
  onMapViewActivated: () => void
  gridRef: React.RefObject<HTMLDivElement>
  children?: React.ReactNode
}

const ResultsGrid: React.FC<ResultsGridProps> = ({
  children,
  gridRef,
  onMapViewActivated,
}) => {
  const activeView = useSelect((state) => state.uiState.activeView)
  const checkIn = useSelect((state) => state.search.selectedDates?.start)
  const checkOut = useSelect((state) => state.search.selectedDates?.end)
  const isSearching = useSelect((state) => state.search.isSearching)
  const numHits = useSelect((state) => state.search.numHits)
  const page = useSelect((state) => state.search.page)
  const appDispatch = useDispatch()

  const handleMapViewChange = useCallback(() => {
    const isMapViewActive = activeView === 'map'
    if (isMapViewActive) {
      appDispatch(setActiveView('grid'))
    } else {
      appDispatch(setActiveView('map'))
      onMapViewActivated()
    }
  }, [activeView, appDispatch, onMapViewActivated])

  return (
    <div
      className={`${style.resultsGrid} ${
        activeView === 'map' && style.resultsGridHidden
      }`}
      ref={gridRef}
    >
      {numHits ? (
        <div className={style.gridHelpers}>
          {!isSearching ? (
            <>
              <Overview
                className="left-aligned"
                itemsPerPage={20}
                page={page + 1}
                totalItems={numHits}
              />
              <ABTest id="AB_TEST_GP341_Variant1" isVariant>
                <CancellationPolicyOnSearchGP341
                  checkInDate={checkIn ? dayjs(checkIn).format() : undefined}
                  checkOutDate={checkOut ? dayjs(checkOut).format() : undefined}
                />
              </ABTest>
              <ABTest id="AB_TEST_GP477_Variant1" isVariant>
                <CancellationPolicyOnSearchGP477 />
              </ABTest>
            </>
          ) : (
            <BarLoader height={21} width={149} />
          )}
        </div>
      ) : numHits === null && isSearching ? (
        <div className={style.topBar}>
          <BarLoader height={21} width={149} />
          <BarLoader height={21} width={76} />
        </div>
      ) : null}
      <button
        className={style.fabGrid}
        data-testid="mapButton"
        onClick={handleMapViewChange}
      >
        <LocationIcon height={20} width={15} />
        <div>View map</div>
      </button>
      {children}
    </div>
  )
}

export default ResultsGrid
