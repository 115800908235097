import { useEffect, useState, useMemo } from 'react'

const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => {
    return process.browser
      ? new IntersectionObserver(([entry]) =>
          setIntersecting(entry.isIntersecting),
        )
      : undefined
  }, [ref])

  useEffect(() => {
    if (!observer || !ref.current) return

    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}

export default useOnScreen
