import React, { useState, useEffect, useRef } from 'react'

import useOnScreen from 'hooks/useOnScreen'

type ABTestProps = {
  id: string
  isVariant?: boolean
  className?: string
  children: React.ReactNode
}

/** 
  TODO:
  * Rethink this component to avoid Cumulative Layout Shifts caused by Intersection Observer
  * The current implementation of this component relies on the Intersection Observer API
  * to determine the visibility of its children. However, this approach can lead to Cumulative Layout Shifts (CLS)
  * when the component's children are dynamically loaded or resized, resulting in a poor user experience.
  * https://evolvevrn.atlassian.net/wiki/spaces/SITE/pages/2677145628/SPIKE+CLS+Issues
**/
const ABTest: React.FC<ABTestProps> = ({
  children,
  id,
  isVariant = false,
  className,
}) => {
  const rootElementRef = useRef(null)
  const [hasBeenOnScreen, setHasBeenOnScreen] = useState(false)
  const isOnScreen = useOnScreen(rootElementRef)

  useEffect(() => {
    if (hasBeenOnScreen) {
      return
    }

    if (isOnScreen) {
      setHasBeenOnScreen(true)
    }
  }, [hasBeenOnScreen, isOnScreen])

  return (
    <div
      className={className ?? ''}
      id={id}
      ref={rootElementRef}
      style={{ display: isVariant ? 'none' : 'block' }}
    >
      {hasBeenOnScreen && children}
    </div>
  )
}

export default ABTest
