import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react'
import Head from 'next/head'
import {
  InstantSearch,
  connectHits,
  Configure,
  connectSearchBox,
} from 'react-instantsearch-dom'
import { connectRange } from 'react-instantsearch-core'
import dayjs from 'dayjs'
import isEqual from 'react-fast-compare'
import { useSession } from 'next-auth/react'
import { shallowEqual } from 'react-redux'
import type { SearchState } from 'react-instantsearch-core'
import type { Dexie as DexieType } from 'dexie'
import type { NextPage, GetStaticProps, InferGetStaticPropsType } from 'next'

import { useDispatch, useSelect } from 'store/index'
import { isPetAllowed } from 'store/search/search.selectors'
import {
  resetSearchState,
  setSelectedDates,
  setShouldApplyFilters,
  setSelectedGuests,
  setQuery,
  setSelectedPets,
  setSearchFiltersMounted,
} from 'store/search'

import {
  setActiveView,
  setDesktopFilterOpen,
  setGuestPickerFocused,
} from 'reducers/uiState'

import { PageHistoryContext } from 'context/PageHistoryContext'

import useBodyClass from 'hooks/bodyClass'
import useAugmentedRouter from 'hooks/useAugmentedRouter'
import useAnalytics from 'hooks/useAnalytics'
import type { IRecentSearch } from 'hooks/useRecentSearches'

import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Gtm from 'components/scripts/Gtm'
import FilterBar from 'components/Search/FilterBar'
import Stats from 'components/Search/Search-Results/Stats'
import SearchPagination from 'components/Pagination/SearchPagination'
import ResultsGrid from 'components/Search/Results-Grid/Results-Grid'
import MapResultsGrid from 'components/Search/Results-Grid/MapResultsGrid'
import SearchBox from 'components/Search/SearchBox'
import AlgoliaStateResults from 'components/Search/Search-Results/AlgoliaStateResults'
import AlgoliaHits from 'components/Search/Search-Results/AlgoliaHits'
import Dates from 'components/Search/Filters/Dates/Dates'
import Guests from 'components/Search/Filters/Guests/Guests'
import ResultGridLoader from 'components/Loader/ResultGridLoader'
import NoResults from 'components/Search/Search-Results/NoResults'
import ListingsShown from 'components/Search/Search-Results/ListingsShown'
import MapAlgolia from 'components/Search/Map/MapAlgolia'
import FavoritesLoginModal from 'components/Favorites/FavoritesLogin/FavoritesLoginModal'
import AlgoliaAnalytics from 'components/scripts/AlgoliaAnalytics'
import type { SearchBoxProps } from 'components/Search/SearchBox'

import style from './search.module.scss'

import { algoliaClient, augmentedSearchClient } from 'config/AlgoliaClient'
import { LargeTabletBreakpoint } from 'config/Breakpoints'

import { createURL, getResultSuggestions, searchStateToUrl } from 'utils/Search'
import urlToSearchState from 'utils/search/urlToSearchState'
import { pushToDataLayer } from 'utils/Gtm'
import { fetchFooterData, fetchGlobalOptionsData } from 'utils/staticData'
import getLOSNights from 'utils/strings/getLOSNights'
import getSearchStateFromAppState from 'utils/search/getSearchStateFromAppState'
import withHits from 'utils/search/withHits'
import { isCurrentPageLatestInHistory } from 'utils/pageHistoryUtils'

// Connected Algolia Components
const CustomSearchBox = connectSearchBox<SearchBoxProps>(SearchBox)
const CustomHits = connectHits(AlgoliaHits)
const CustomGuestsFilter = connectRange(Guests)
const VirtualLOSRange = connectRange(() => null)
// Use custom withHits connector
const MapAlgoliaWithHits = withHits(MapAlgolia)

// Create a client for Algolia
const index = algoliaClient.initIndex(
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX!,
)

const InitiatedFromItems = {
  hpsb: 'home page search bar',
  spb: 'search page bar',
  lpb: 'listing page bar',
  fbr: '/favorites',
  hpc1: 'home page component',
  hpc2: 'home page component',
}

const MetaFromSearchState = ({ searchState }: { searchState: SearchState }) => {
  // generate meta description & title tags based on searches for city, state, country -- WT-196
  if (searchState.query) {
    return (
      <Head>
        <title>{`${searchState.query.split(', ')[0]} Vacation Rentals`}</title>
        <meta
          content={`Find vacation rentals in ${
            searchState.query.split(', ')[0]
          } and book directly with Evolve for the guaranteed best rates. We promise clean, private vacation rentals with 24/7 support so you can travel without worry.`}
          name="description"
        />
      </Head>
    )
  } else
    return (
      <Head>
        <title>Vacation Rentals</title>
        <meta content="Find the perfect vacation rental" name="description" />
      </Head>
    )
}

export const getStaticProps: GetStaticProps = async () => {
  const footerData = await fetchFooterData()
  const globalOptionsData = await fetchGlobalOptionsData()

  // get facet values to store in UI
  // https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/#display-facets-with-no-matches
  const { facets, facets_stats } = await index.search('', {
    facets: [
      'Property Type',
      'amenities.Amenities',
      'amenities.Location',
      'amenities.View',
      'amenities.Accessibility',
      'amenities.Area Activities',
      'Bedrooms',
      'Total Beds',
      'Bathrooms',
      'LOS_PriceAverages',
    ],
    hitsPerPage: 0,
    analytics: false,
  })

  return {
    props: {
      footerData: {
        menus: footerData,
        options: globalOptionsData.acf.global_footer_group,
      },
      cachedFacets: facets,
      cachedFacetsStats: facets_stats,
    },
  }
}

const SearchPage: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  footerData,
  cachedFacets,
  cachedFacetsStats,
}) => {
  useBodyClass('Search')
  const [startDate, setStartDate] = useState<string | null>('')
  const [endDate, setEndDate] = useState<string | null>('')
  const router = useAugmentedRouter()
  const appDispatch = useDispatch()
  const { pageHistory } = useContext(PageHistoryContext)
  const analytics = useAnalytics()

  const { data: session } = useSession()

  const [searchState, setSearchState] = useState<SearchState>(
    urlToSearchState(router.asPath),
  )
  const [trackInfo, setTrackInfo] = useState<SearchState>({})
  const [prevInitiatedFrom, setPrevInitiatedFrom] = useState('')
  const [debouncedSetState, setDebouncedSetState] = useState<number>()

  const DEBOUNCE_TIME = 400

  const cardRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<HTMLDivElement>(null)
  const [guests, setGuests] = useState({
    adults: searchState.guests?.adults ? +searchState.guests.adults : 0,
    children: searchState.guests?.children ? +searchState.guests.children : 0,
    infants: searchState.guests?.infants ? +searchState.guests.infants : 0,
    pets: searchState.guests?.pets ? +searchState.guests.pets : 0,
    total:
      (searchState.guests?.adults ? +searchState.guests.adults : 0) +
      (searchState.guests?.children ? +searchState.guests.children : 0),
  })

  const width = useSelect((state) => state.uiState.width)
  const activeView = useSelect((state) => state.uiState.activeView)
  const isSearching = useSelect((state) => state.search.isSearching)
  const numHits = useSelect((state) => state.search.numHits)
  const searchParameters = useSelect(
    (state) => state.search.searchParameters,
    shallowEqual,
  )
  const mobileSearchBoxFocused = useSelect(
    (state) => state.uiState.mobileSearchBoxFocused,
  )

  const desktopFilterOpen = useSelect(
    (state) => state.uiState.desktopFilterOpen,
  )

  const mobileFiltersOpen = useSelect(
    (state) => state.uiState.mobileFiltersOpen,
  )
  const shouldApplyFilters = useSelect(
    (state) => state.search.shouldApplyFilters,
  )
  const selectedDates = useSelect((state) => state.search.selectedDates)
  const selectedAmenities = useSelect(
    (state) => state.search.selectedAmenities,
    shallowEqual,
  )
  const selectedLocationAmenities = useSelect(
    (state) => state.search.selectedLocationAmenities,
    shallowEqual,
  )
  const selectedViewAmenities = useSelect(
    (state) => state.search.selectedViewAmenities,
    shallowEqual,
  )
  const selectedPropertyTypes = useSelect(
    (state) => state.search.selectedPropertyTypes,
    shallowEqual,
  )
  const selectedAccessibility = useSelect(
    (state) => state.search.selectedAccessibility,
    shallowEqual,
  )
  const selectedMinBeds = useSelect((state) => state.search.selectedMinBeds)
  const selectedMinBathrooms = useSelect(
    (state) => state.search.selectedMinBathrooms,
  )
  const selectedMinBedrooms = useSelect(
    (state) => state.search.selectedMinBedrooms,
  )
  const selectedMinPrice = useSelect((state) => state.search.selectedMinPrice)
  const selectedMaxPrice = useSelect((state) => state.search.selectedMaxPrice)
  const guestPickerFocused = useSelect(
    (state) => state.uiState.guestPickerFocused,
  )
  const datePickerFocused = useSelect(
    (state) => state.uiState.datePickerFocused,
  )
  const shouldLoadGtm = useSelect((state) => state.search.shouldLoadGtm)
  const startDateFromUrl = useMemo(
    () =>
      searchState.dates?.start &&
      dayjs(searchState.dates.start, 'YYYYMMDD').format('YYYYMMDD'),
    [searchState.dates?.start],
  )
  const endDateFromUrl = useMemo(
    () =>
      searchState.dates?.end &&
      dayjs(searchState.dates.end, 'YYYYMMDD').format('YYYYMMDD'),
    [searchState.dates?.end],
  )
  const loginPromptModalOpen = useSelect(
    (state) => state.uiState.loginPromptModalOpen,
  )
  const saveAsGuest = useSelect((state) => state.favorites.saveAsGuest)

  const maxGuests = 99
  const nights = useMemo(
    () => getLOSNights(startDateFromUrl, endDateFromUrl),
    [endDateFromUrl, startDateFromUrl],
  )

  const petsAllowed = useSelect(isPetAllowed)
  const { clearSearchClicked } = useAnalytics()

  // Only set dates in store after user closes calendar, so it does not immediately search after picking dates.
  useEffect(() => {
    if (!datePickerFocused && startDate && endDate) {
      appDispatch(
        setSelectedDates({
          start: startDate ?? '',
          end: endDate ?? '',
        }),
      )
    }
  }, [appDispatch, startDate, endDate, datePickerFocused])

  const handleMapViewChange = useCallback(() => {
    if (activeView === 'map') {
      appDispatch(setActiveView('grid'))
    } else {
      appDispatch(setActiveView('map'))
    }
  }, [activeView, appDispatch])

  const handleQuerySelect = useCallback(
    (query: string) => {
      appDispatch(setQuery(query))
    },
    [appDispatch],
  )

  const handleDatesChange = useCallback(
    (startDate: string | null, endDate: string | null) => {
      setStartDate(startDate)
      setEndDate(endDate)
      if (!guests.adults) {
        appDispatch(setGuestPickerFocused(true))
      }
    },
    [appDispatch, guests],
  )

  const handleDatesClear = useCallback(() => {
    setStartDate('')
    setEndDate('')
    appDispatch(
      setSelectedDates({
        start: '',
        end: '',
      }),
    )
    appDispatch(setShouldApplyFilters(true))
    clearSearchClicked({
      selectedDates,
      initializedFrom: 'Calendar Modal',
      clickedFrom: 'Clear Dates',
    })
    //@TODO ask what expected behavior is
  }, [appDispatch, clearSearchClicked, selectedDates])

  const handleGuestsChange = useCallback(
    (adultGuests, childGuests, infantGuests, petGuests, totalGuests) => {
      appDispatch(setSelectedPets({ isPetAllowed: petGuests }))
      appDispatch(setSelectedGuests(totalGuests))
      setGuests({
        adults: adultGuests,
        children: childGuests,
        infants: infantGuests,
        pets: petGuests,
        total: totalGuests,
      })
      // push guests & pets change event to GTM data layer
      pushToDataLayer('guestCount', { guestCount: totalGuests })
      pushToDataLayer('petsAdded', { petsAdded: petGuests === 1 })
    },
    [appDispatch],
  )

  const handleGuestsClear = useCallback(() => {
    appDispatch(setSelectedPets({ isPetAllowed: 0 }))
    appDispatch(setSelectedGuests(0))
    setGuests({
      adults: 0,
      children: 0,
      infants: 0,
      pets: 0,
      total: 0,
    })
    // push guests & pets change event to GTM data layer
    pushToDataLayer('guestCount', { guestCount: 0 })
    pushToDataLayer('petsAdded', { petsAdded: false })
  }, [appDispatch])

  const handlePaginate = useCallback(() => {
    gridRef.current?.scrollTo(0, 0)
  }, [])

  const onSearchStateChange = useCallback(
    (updatedSearchState: SearchState) => {
      if (
        updatedSearchState.query &&
        (!searchState.query || searchState.query !== updatedSearchState.query)
      ) {
        // remove the timeout while user is typing
        clearTimeout(debouncedSetState)
      }

      if (
        (!selectedDates?.start && !selectedDates?.end) ||
        (selectedDates && updatedSearchState.configure?.filters)
      ) {
        updatedSearchState.dates = selectedDates
      }

      if (updatedSearchState['guests'] !== guests) {
        const { pets, ...rest } = guests
        updatedSearchState['guests'] = {
          ...updatedSearchState['guests'],
          ...rest,
        }
        updatedSearchState.range = {
          ...updatedSearchState.range,
          'Max Occupancy': {
            min: guests.total,
            max: maxGuests,
          },
        }
      }

      if (!updatedSearchState.range?.['Max Occupancy']) {
        updatedSearchState.range = {
          ...updatedSearchState.range,
          'Max Occupancy': {
            min: guests.total,
            max: maxGuests,
          },
        }
      }

      if (
        updatedSearchState.query &&
        updatedSearchState.query !== searchState.query
      ) {
        // if query changes remove page & boundingBox params
        delete updatedSearchState.boundingBox
        delete updatedSearchState.page
      }

      if (updatedSearchState.boundingBox) {
        updatedSearchState.query = ''
        updatedSearchState.aroundLatLng = ''
      }

      const newQueryString = searchStateToUrl(updatedSearchState)

      setDebouncedSetState(
        window.setTimeout(() => {
          router.push(router.pathname + newQueryString, undefined, {
            shallow: true,
          })
        }, DEBOUNCE_TIME),
      )

      setSearchState(updatedSearchState)
    },
    [debouncedSetState, router, searchState, selectedDates, guests],
  )

  useEffect(() => {
    router.beforePopState(() => {
      if (window.confirm('Are you sure you want to leave your search?')) {
        return true
      } else return false
    })
  }, [router])

  useEffect(() => {
    if (width) {
      if (width <= LargeTabletBreakpoint) {
        appDispatch(setActiveView('grid'))
      } else {
        appDispatch(setActiveView('both'))
      }
    }
  }, [appDispatch, width])

  // Apply filters selected from More Filters
  useEffect(() => {
    if (shouldApplyFilters) {
      const { range, refinementList } = getSearchStateFromAppState({
        selectedMinBeds,
        selectedMinBathrooms,
        selectedMinBedrooms,
        selectedDates,
        selectedMinPrice,
        selectedMaxPrice,
        selectedAmenities,
        selectedLocationAmenities,
        selectedViewAmenities,
        selectedPropertyTypes,
        selectedAccessibility,
      })

      const guestsUpdatedByPets = {
        ...guests,
        pets: Number(petsAllowed),
      }

      const updatedSearch = {
        ...searchState,
        guests: guestsUpdatedByPets,
      }

      setGuests(guestsUpdatedByPets)
      onSearchStateChange({
        ...updatedSearch,
        range,
        refinementList,
      })
      appDispatch(setShouldApplyFilters(false))
    }
  }, [
    onSearchStateChange,
    appDispatch,
    searchState,
    selectedAmenities,
    selectedDates,
    selectedMinBathrooms,
    selectedMinBeds,
    selectedMinBedrooms,
    selectedMinPrice,
    selectedMaxPrice,
    selectedPropertyTypes,
    selectedAccessibility,
    shouldApplyFilters,
    selectedLocationAmenities,
    selectedViewAmenities,
    startDateFromUrl,
    endDateFromUrl,
    nights,
    guests,
    petsAllowed,
  ])

  // Reset search state and numHits in redux when navigating back to this page
  useEffect(() => {
    appDispatch(resetSearchState(urlToSearchState(window.location.search)))
  }, [appDispatch])

  useEffect(() => {
    const handleRouteChange = async (
      url: string,
      { shallow }: { shallow: boolean },
    ) => {
      if (url.includes('?query=') && shallow) {
        const currentSearchState = urlToSearchState(router.asPath)
        const newSearchState = urlToSearchState(url)

        const filteredCurrentQueryParams = {
          'query': currentSearchState.query,
          'adults': currentSearchState.guests?.adults,
          'children': currentSearchState.guests?.children,
          'infants': currentSearchState.guests?.infants,
          'pets': currentSearchState.guests?.pets,
          'startDate': currentSearchState.startDate,
          'endDate': currentSearchState.endDate,
          'amenities.Accessibility':
            currentSearchState.guests?.pets === '1'
              ? 'Pets-Allowed'
              : undefined,
        }

        const filteredNewQueryParams = {
          'query': newSearchState.query,
          'adults': newSearchState.guests?.adults,
          'children': newSearchState.guests?.children,
          'infants': newSearchState.guests?.infants,
          'pets': newSearchState.guests?.pets,
          'startDate': newSearchState.startDate,
          'endDate': newSearchState.endDate,
          'amenities.Accessibility':
            newSearchState.guests?.pets === '1' ? 'Pets-Allowed' : undefined,
        }

        if (!isEqual(filteredCurrentQueryParams, filteredNewQueryParams)) {
          const { Dexie } = await import('dexie')

          class RecentSearchDatabase extends Dexie {
            searches: DexieType.Table<IRecentSearch, string>

            constructor() {
              super('RecentSearchDatabase')
              this.version(1).stores({
                searches: 'timestamp',
              })
              this.searches = this.table('searches')
            }
          }

          const db = new RecentSearchDatabase()

          let newQueryString = searchStateToUrl(searchState)

          if (url.includes('if=')) {
            const searchParams = new URLSearchParams(newQueryString)
            searchParams.delete('if=')
            newQueryString = `?${searchParams.toString()}`
          }

          db.searches.add({
            query: newQueryString,
            data: filteredNewQueryParams,
            timestamp: new Date().valueOf(),
          })
        }
      }
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, router.asPath, searchState, appDispatch])

  useEffect(() => {
    appDispatch(setSearchFiltersMounted())
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isCurrentPageLatestInHistory(pageHistory)) {
        if (pageHistory.length === 1) {
          analytics.viewSearchResultsPage()
        } else {
          analytics.viewSearchResultsPage({
            previousPage: pageHistory[pageHistory.length - 2],
          })
        }
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [analytics, router.query.page, pageHistory, searchState])

  useEffect(() => {
    return () => {
      appDispatch(setDesktopFilterOpen(false))
    }
  }, [])

  const handleSendTrackEvent = useCallback(
    (data: any) => {
      const searchParams = new URL(window.location.href).searchParams
      let initiated_from = ''
      if (
        searchParams.get('if') &&
        prevInitiatedFrom !== searchParams.get('if')
      ) {
        initiated_from = searchParams.get('if') as string
        setPrevInitiatedFrom(initiated_from)
      }
      if (!isEqual(data, trackInfo)) {
        const storage = globalThis?.sessionStorage
        const prevPath = storage?.getItem('prevPath') || ''
        const { prevListingPage, ...rest } = router.query
        window.analytics?.track('Products Searched', {
          ...data,
          query: window.location.href.split('?')[1] || '',
          initiated_from:
            InitiatedFromItems[initiated_from] || prevListingPage || prevPath,
        })
        setTrackInfo(data)
        router.push(
          {
            pathname: router.pathname,
            query: rest,
          },
          undefined,
          { shallow: true },
        )
      }
    },
    [prevInitiatedFrom, trackInfo, router],
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      const priceRange = Object.entries(searchState.range || {}).find(
        (itm) =>
          itm[0].indexOf('Average Per Night') !== -1 ||
          itm[0].indexOf('PriceAverages') !== -1,
      )

      const amenities =
        Object.entries(searchState.refinementList || {})
          .filter((itm) => itm[0].indexOf('amenities') !== -1)
          .reduce((bef: string[], af) => {
            return [...bef, ...af[1]]
          }, []) || ''
      const location = searchState.query?.split(',') || []
      const country = location[location?.length - 1] || ''
      const region = location[location?.length - 2] || ''
      const city =
        location
          .slice(0, location?.length - (location?.length > 2 ? 2 : 1))
          .join(',') || ''

      const trackInfo = {
        // initiated_from,
        travel_start:
          searchState.dates?.start?.replace(
            /(\d{4})(\d{2})(\d{2})/g,
            '$1-$2-$3 ',
          ) || '',
        travel_end:
          searchState.dates?.end?.replace(
            /(\d{4})(\d{2})(\d{2})/g,
            '$1-$2-$3 ',
          ) || '',
        num_adults: parseInt(searchState.guests?.adults) || 0,
        num_children: parseInt(searchState.guests?.children) || 0,
        num_infants: parseInt(searchState.guests?.infants) || 0,
        num_pets: parseInt(searchState.guests?.pets) || 0,
        city,
        region,
        country,
        beds: searchState.range?.['Total Beds']?.min || 0,
        bedrooms: searchState.range?.Bedrooms?.min || 0,
        bathrooms: searchState.range?.Bathrooms?.min || 0,
        property_type: searchState.refinementList?.['Property Type'] || '',
        price_min: (priceRange || [])[1]?.min || 0,
        price_max: (priceRange || [])[1]?.max || 0,
        amenities,
      }
      handleSendTrackEvent(trackInfo)
    }, 1000)
    return () => clearTimeout(timer)
  }, [handleSendTrackEvent, searchState])

  const switchMobileMapViewAnalytics = useCallback(() => {
    if (
      pageHistory[pageHistory.length - 1]?.split('?')[0] ===
      window.location.href.split('?')[0]
    ) {
      if (pageHistory.length === 1) {
        analytics.viewSearchMap()
      } else {
        analytics.viewSearchMap({
          previousPage: pageHistory[pageHistory.length - 2],
        })
      }
    }
  }, [pageHistory, analytics])

  return (
    <>
      {/* 
      // @ts-expect-error react implicit children. TODO Update react-instantsearch-dom */}
      <InstantSearch
        createURL={createURL}
        indexName={`${process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX}`}
        onSearchStateChange={onSearchStateChange}
        searchClient={augmentedSearchClient}
        searchState={searchState}
      >
        <Head>
          <link
            href={`https://evolve.com/vacation-rentals/search`}
            rel="canonical"
          />
          {Object.keys(router.query).length > 0 ? (
            <meta content="noindex" name="robots" />
          ) : null}
        </Head>
        <Gtm pageType="search results page" wait={!shouldLoadGtm} />
        <AlgoliaAnalytics />
        <MetaFromSearchState searchState={searchState} />
        <Configure {...searchParameters} />
        <VirtualLOSRange
          attribute={`LOS_PriceAverages.${startDateFromUrl}.${nights}`}
        />
        <Stats />
        <AlgoliaStateResults />
        <ListingsShown />
        <Header fullNavWidth={1334} page="search">
          <CustomSearchBox onQuerySelect={handleQuerySelect} />
          <Dates
            combinedInputs
            initialEndDate={endDateFromUrl}
            initialStartDate={startDateFromUrl}
            onApply={handleDatesChange}
            onClearDates={handleDatesClear}
            small
          />
          <CustomGuestsFilter
            attribute="Max Occupancy"
            hasIcon
            max={99}
            onApply={handleGuestsChange}
            onClearGuests={handleGuestsClear}
            small={true}
            value={guests}
          />
        </Header>

        <div
          className={`${
            (activeView === 'map' ||
              mobileSearchBoxFocused ||
              (guestPickerFocused && width <= 900)) &&
            !mobileFiltersOpen
              ? style.searchTopBarWrapperHidden
              : style.searchTopBarWrapper
          }`}
        >
          <FilterBar
            cachedFacets={cachedFacets}
            cachedFacetsStats={cachedFacetsStats}
          />
        </div>
        <div
          className={`${style.searchWrapper} ${
            desktopFilterOpen && style.searchWrapperOverlay
          }`}
        >
          <ResultsGrid
            gridRef={gridRef}
            onMapViewActivated={switchMobileMapViewAnalytics}
          >
            {isSearching && activeView !== 'map' ? (
              <ResultGridLoader gridLength={20} />
            ) : (
              <>
                {!isSearching && numHits === 0 && (
                  <NoResults>
                    <NoResults.Text
                      message={getResultSuggestions(selectedDates)}
                    />
                    <NoResults.Buttons />
                  </NoResults>
                )}
                <CustomHits />
              </>
            )}
            <SearchPagination onPaginate={handlePaginate} />
          </ResultsGrid>

          <MapAlgoliaWithHits
            cardRef={cardRef}
            onMapViewClick={handleMapViewChange}
          />

          {activeView === 'map' && (
            <div ref={cardRef}>
              <MapResultsGrid></MapResultsGrid>
            </div>
          )}
        </div>
        {loginPromptModalOpen && !saveAsGuest && !session && (
          <FavoritesLoginModal />
        )}
      </InstantSearch>
      <Footer footerData={footerData} />
    </>
  )
}

export default SearchPage
