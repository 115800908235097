import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react'
import type { SearchResponse } from '@algolia/client-search'

import { useDispatch, useSelect } from 'store/index'
import {
  setShouldApplyFilters,
  backgroundSearch,
  clearPropertyTypeFilter,
} from 'store/search'

import { setDesktopFilterOpen } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import ClickableFilters from 'components/Search/ClickableFilters'
import Amenities from 'components/Search/Filters/Amenities/Amenities'

import style from './PropertyType.module.scss'

import { useExpandedFilter } from '../useExpandedFilter'
import useClickOutSideFilters from '../useClickOutSideFilters'

import urlToSearchState from 'utils/search/urlToSearchState'

import CaretIcon from 'assets/icons/icon-caret.svg'

type PropertyTypeProps = {
  cachedFacets: SearchResponse['facets']
}

const PropertyType: React.FC<PropertyTypeProps> = ({ cachedFacets }) => {
  const router = useAugmentedRouter()

  const [filterOpen, setFilterOpen] = useState(false)
  const numResults = useSelect((state) => state.search.numResults)
  const propertyTypeFilterRefined = useSelect(
    (state) => state.search.propertyTypeFilterRefined,
  )
  const searchFiltersMounted = useSelect(
    (state) => state.search.searchFiltersMounted,
  )
  const selectedPropertyTypes = useSelect(
    (state) => state.search.selectedPropertyTypes,
  )

  const hasPropertyTypeFilterRefined = useMemo(
    () =>
      propertyTypeFilterRefined ??
      urlToSearchState(router.asPath).refinementList?.['Property Type'].length,
    [propertyTypeFilterRefined, router.asPath],
  )

  const elRef = useRef<HTMLDivElement>(null)

  const appDispatch = useDispatch()

  const handleApply = useCallback(
    (maintainDesktopFilter: boolean) => {
      setFilterOpen(false)
      if (!maintainDesktopFilter) {
        appDispatch(setDesktopFilterOpen(false))
      }
      window.scrollTo(0, 0)
      document.body.classList.remove('noScroll', 'fullWidth')
      appDispatch(setShouldApplyFilters(true))
    },
    [appDispatch],
  )

  const handleClear = () => {
    appDispatch(clearPropertyTypeFilter())
    appDispatch(backgroundSearch())
  }

  useClickOutSideFilters({
    ref: elRef,
    isOpen: filterOpen,
    onClickOutSide: handleApply,
  })

  const { selectedFiltersLength, expandedFilterStyle } = useExpandedFilter(
    selectedPropertyTypes.length,
    style,
  )

  return (
    <div className={`${style.select} ${expandedFilterStyle}`} ref={elRef}>
      <div
        aria-hidden="true"
        className={`${style.value} ${filterOpen ? style.focused : ''} 
        filter-category-button`}
        data-testid="propertyTypeBtn"
        onClick={() => {
          if (filterOpen) {
            document.body.classList.remove('noScroll', 'fullWidth')
            appDispatch(setDesktopFilterOpen(false))
          } else {
            document.body.classList.add('noScroll', 'fullWidth')
            appDispatch(setDesktopFilterOpen(true))
          }
          setFilterOpen((filterOpen) => !filterOpen)
        }}
      >
        <strong>Property Type {selectedFiltersLength}</strong>
        <CaretIcon
          className={`${style.caret} ${filterOpen ? style.focused : ''}`}
          height="7px"
          width="12px"
        />
      </div>
      <div className={`${style.options} ${filterOpen ? style.focused : ''}`}>
        {filterOpen && <ClickableFilters attribute="Property Type" />}
        <div>
          <div className={style.amenities}>
            <Amenities
              attribute="Property Type"
              cachedFacets={cachedFacets?.['Property Type']}
              displayLimit={10}
              limit={300}
              operator={'or'}
              showMoreLimit={300}
            />
          </div>
          <footer className={style.footer}>
            <button
              className={`${style.btn} ${style.clearBtn}`}
              disabled={searchFiltersMounted && !hasPropertyTypeFilterRefined}
              onClick={() => {
                handleClear()
              }}
            >
              Clear
            </button>
            <button
              className={`${style.btn} ${style.submitBtn}`}
              disabled={!numResults}
              onClick={() => {
                handleApply(false)
              }}
            >
              {`View ${numResults} ${numResults === 1 ? 'Result' : 'Results'}`}
            </button>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default PropertyType
