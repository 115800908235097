import React, { useState, useEffect, useRef } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import dayjs from 'dayjs'
import isEqual from 'lodash/isEqual'
import type {
  Hit,
  SearchResults,
  StateResultsProvided,
  SearchState,
} from 'react-instantsearch-core'

import { useSelect } from 'store/index'

import { pushToDataLayer } from 'utils/Gtm'
import { formatGtmLocationData } from 'utils/Search'

import type { Guests, Dates } from 'types/searchState'
import { GeocoderQueryAndResult } from 'types/externalData'
// Add queryID to StateResultsProvided.SearchResults

type ExtendedSearchResults = SearchResults & { queryID: string }
interface ListingsShownProps
  extends Omit<StateResultsProvided, 'SearchResults'> {
  searchResults: ExtendedSearchResults
}
type LastSearchState =
  | (Partial<SearchState> & { guests: Guests; dates: Dates })
  | Record<string, never>

const ListingsShown = ({ searchState, searchResults }: ListingsShownProps) => {
  const gtmLocationData = useSelect((state) => state.search.gtmLocationData)
  const isSearching = useSelect((state) => state.search.isSearching)
  const gtmLoaded = useSelect((state) => state.search.gtmLoaded)

  const [lastResult, setLastResult] = useState<ExtendedSearchResults | null>(
    null,
  )
  const lastSearchState = useRef<LastSearchState>({})
  const locationDataRef = useRef<GeocoderQueryAndResult>(gtmLocationData)

  // Wait until search update is complete before setting component search result state
  useEffect(() => {
    if (!isSearching && searchResults) {
      const { configure, ...searchStateObj } = searchState as LastSearchState
      if (
        (searchResults.query !== lastResult?.query &&
          searchResults.queryID !== lastResult?.queryID) ||
        !isEqual(searchStateObj, lastSearchState.current)
      ) {
        setLastResult(searchResults)
        lastSearchState.current = searchStateObj
      }
    }
  }, [isSearching, searchResults, searchState, lastResult])

  useEffect(() => {
    locationDataRef.current = gtmLocationData
  }, [gtmLocationData])

  // Push listingsShown event to data layer when component state is ready
  useEffect(() => {
    if (lastResult && gtmLoaded) {
      const location = formatGtmLocationData(locationDataRef.current)
      pushToDataLayer('view_item_list', {
        listingsShown: JSON.stringify(
          lastResult.hits.map((hit: Hit) => hit.objectID),
        ),
        city: location.city ?? '',
        region: location.region ?? '',
        country: location.country ?? '',
        travel_start: lastSearchState.current?.dates?.start
          ? dayjs(lastSearchState.current.dates.start).format('YYYY-MM-DD')
          : '',
        travel_end: lastSearchState.current?.dates?.end
          ? dayjs(lastSearchState.current.dates.end).format('YYYY-MM-DD')
          : '',
        num_adults: lastSearchState.current?.guests?.adults
          ? lastSearchState.current?.guests?.adults
          : '',
        num_children: lastSearchState.current?.guests?.children
          ? lastSearchState.current.guests.children
          : '',
        ecommerce: {
          items: lastResult.hits.map((hit: Hit) => ({
            item_id: hit.objectID,
            item_name: hit.Headline,
            item_category: hit['Property Type'],
            item_list_name: 'Search Results',
            // Trailing space is required
            location_id: hit['Evolve Search Listing Score '],
            price: hit['Average Per Night'],
          })),
        },
      })
    }
    // Prevent event firing unless internal state changes
  }, [lastResult, gtmLoaded])

  return <></>
}

export default connectStateResults(ListingsShown)
