import React, { useCallback } from 'react'
import { connectRange } from 'react-instantsearch-core'

import { useSelect, useDispatch } from 'store/index'
import {
  filterMinBathrooms,
  filterMinBedrooms,
  filterMinBeds,
} from 'store/search'

import useTimeout from 'hooks/useTimeout'
import useAugmentedRouter from 'hooks/useAugmentedRouter'

import IncrementDecrement from 'components/Forms/IncrementDecrement'

import style from './MinRangePicker.module.scss'

import urlToSearchState from 'utils/search/urlToSearchState'

type MinRangePickerProps = {
  attribute: string
  min: number
  max: number
  cachedFacetsStats?: {
    min: number
    max: number
    avg: number
    sum: number
  }
}

const MinRangePicker: React.FC<MinRangePickerProps> = ({
  attribute,
  max,
  cachedFacetsStats,
}) => {
  const router = useAugmentedRouter()

  const limit = cachedFacetsStats?.max
    ? Math.ceil(cachedFacetsStats['max'])
    : max

  const label =
    attribute === 'Bedrooms'
      ? 'Bedrooms'
      : attribute === 'Bathrooms'
      ? 'Bathrooms'
      : attribute === 'Total Beds'
      ? 'Beds'
      : ''

  const selectedMin = useSelect((state) => {
    switch (attribute) {
      case 'Bedrooms':
        return state.search.selectedMinBedrooms !== undefined
          ? state.search.selectedMinBedrooms
          : urlToSearchState(router.asPath).minBedrooms ?? 0
      case 'Bathrooms':
        return state.search.selectedMinBathrooms !== undefined
          ? state.search.selectedMinBathrooms
          : urlToSearchState(router.asPath).minBathrooms ?? 0
      case 'Total Beds':
        return state.search.selectedMinBeds !== undefined
          ? state.search.selectedMinBeds
          : urlToSearchState(router.asPath).minBeds ?? 0
      default:
        break
    }
  })

  const appDispatch = useDispatch()

  const timeout = useTimeout()

  const handleChange = useCallback(
    (value: number) => {
      if (document.body.classList.value.includes('Search')) {
        timeout.setIsRunning(true)
      }

      switch (attribute) {
        case 'Bedrooms':
          appDispatch(filterMinBedrooms(value))
          break
        case 'Bathrooms':
          appDispatch(filterMinBathrooms(value))
          break
        case 'Total Beds':
          appDispatch(filterMinBeds(value))
          break
        default:
          break
      }
    },
    [appDispatch, attribute, timeout],
  )

  return (
    <div
      className={`${style.picker} ${
        timeout.isRunning ? style.touchHilight : ''
      }`}
    >
      <h4 className={style.label}>{label}</h4>
      <IncrementDecrement
        max={limit}
        min={0}
        onChange={handleChange}
        value={selectedMin ?? 0}
      />
    </div>
  )
}

export default connectRange(MinRangePicker)
