import React, { useRef, useCallback, useState, useMemo } from 'react'
import type { SearchResponse } from '@algolia/client-search'

import { useDispatch, useSelect } from 'store/index'
import {
  setShouldApplyFilters,
  backgroundSearch,
  clearAmenitiesFilter,
} from 'store/search'

import { setDesktopFilterOpen } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import ClickableFilters from 'components/Search/ClickableFilters'

import style from './AmenitiesGroup.module.scss'

import Amenities from '../Amenities/Amenities'
import useClickOutSideFilters from '../useClickOutSideFilters'
import { useExpandedFilter } from '../useExpandedFilter'

import urlToSearchState from 'utils/search/urlToSearchState'

import CaretIcon from 'assets/icons/icon-caret.svg'

type AmenitiesGroupProps = {
  cachedFacets: SearchResponse['facets']
}

const AmenitiesGroup: React.FC<AmenitiesGroupProps> = ({ cachedFacets }) => {
  const router = useAugmentedRouter()

  const [filterOpen, setFilterOpen] = useState(false)

  const numResults = useSelect((state) => state.search.numResults)
  const amenitiesFilterRefined = useSelect(
    (state) => state.search.amenitiesFilterRefined,
  )
  const showMoreAmenitiesOpen = useSelect(
    (state) => state.uiState.showMoreAmenities,
  )
  const searchFiltersMounted = useSelect(
    (state) => state.search.searchFiltersMounted,
  )

  const selectedAmenities = useSelect((state) => state.search.selectedAmenities)

  const hasAmenitiesFilterRefined = useMemo(
    () =>
      amenitiesFilterRefined ??
      urlToSearchState(router.asPath).refinementList?.['amenities.Amenities']
        .length,
    [amenitiesFilterRefined, router.asPath],
  )

  const elRef = useRef<HTMLDivElement>(null)

  const appDispatch = useDispatch()

  const handleApply = useCallback(
    (maintainDesktopFilter: boolean) => {
      appDispatch(setShouldApplyFilters(true))
      setFilterOpen(false)
      if (!maintainDesktopFilter) {
        appDispatch(setDesktopFilterOpen(false))
      }
      window.scrollTo(0, 0)
      document.body.classList.remove('noScroll', 'fullWidth')
    },
    [appDispatch],
  )

  const handleClear = () => {
    appDispatch(clearAmenitiesFilter())
    appDispatch(backgroundSearch())
  }

  useClickOutSideFilters({
    ref: elRef,
    isOpen: filterOpen,
    onClickOutSide: handleApply,
  })

  const { selectedFiltersLength, expandedFilterStyle } = useExpandedFilter(
    selectedAmenities.length,
    style,
  )

  return (
    <div className={`${style.select} ${expandedFilterStyle}`} ref={elRef}>
      <div
        aria-hidden="true"
        className={`${style.value} ${
          filterOpen ? style.focused : ''
        } filter-category-button`}
        data-testid="amenitiesBtn"
        onClick={() => {
          if (filterOpen) {
            document.body.classList.remove('noScroll', 'fullWidth')
            appDispatch(setDesktopFilterOpen(false))
          } else {
            document.body.classList.add('noScroll', 'fullWidth')
            appDispatch(setDesktopFilterOpen(true))
          }
          setFilterOpen((filterOpen) => !filterOpen)
        }}
      >
        <strong>Amenities {selectedFiltersLength}</strong>
        <CaretIcon
          className={`${style.caret} ${filterOpen ? style.focused : ''}`}
          height="7px"
          width="12px"
        />
      </div>
      <div className={`${style.options} ${filterOpen ? style.focused : ''}`}>
        <div>
          <div className={style.amenities}>
            {filterOpen && <ClickableFilters attribute="amenities.Amenities" />}
            <Amenities
              attribute="amenities.Amenities"
              cachedFacets={cachedFacets?.['amenities.Amenities']}
              displayLimit={10}
              limit={300}
              operator={'and'}
              showMoreLimit={300}
            />
          </div>
          <footer className={`${style.footer} ${style.shadowed}`}>
            <button
              className={`${style.btn} ${style.clearBtn}`}
              disabled={searchFiltersMounted && !hasAmenitiesFilterRefined}
              onClick={() => {
                handleClear()
              }}
            >
              Clear
            </button>
            <button
              className={`${style.btn} ${style.submitBtn}`}
              disabled={!numResults}
              onClick={() => {
                handleApply(false)
              }}
            >
              {`View ${numResults} ${numResults === 1 ? 'Result' : 'Results'}`}
            </button>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default AmenitiesGroup
