import React from 'react'

import { useSelect } from 'store/index'
import {
  getSelectedClickableFilters,
  getSelectedClickableFiltersArray,
} from 'store/search/search.selectors'

import { ClickableFilter } from './ClickableFilter'
import { ClickableFiltersProps } from './ClickableFilters.types'
import style from './ClickableFilters.module.scss'
import { ClickableAttributes } from './ClickableFilters.enums'

export const ClickableFilters = ({
  attribute = ClickableAttributes.ALL,
}: ClickableFiltersProps) => {
  const selectedClickableFiltersMap = useSelect(getSelectedClickableFilters)
  const selectedClickableFilters = useSelect((state) =>
    getSelectedClickableFiltersArray(state, attribute),
  )
  if (!selectedClickableFilters.length) return null

  return (
    <section className={style.clickableFiltersList}>
      {selectedClickableFilters.map((selectedFilter, i) => (
        <ClickableFilter
          key={`${i}_${selectedFilter}`}
          selectedClickableFiltersMap={selectedClickableFiltersMap}
          selectedFilter={selectedFilter}
        />
      ))}
    </section>
  )
}
