import { useEffect, useState } from 'react'
type UseExpandedFilter = (
  selectedLength: number,
  style: any,
) => {
  selectedFiltersLength?: JSX.Element | string
  expandedFilterStyle?: string
}

export const useExpandedFilter: UseExpandedFilter = (selectedLength, style) => {
  const [isMounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])
  const selectedFiltersLength = selectedLength ? (
    <>
      (<strong>{selectedLength}</strong>)
    </>
  ) : (
    ''
  )
  const expandedFilterStyle =
    selectedLength && isMounted ? style.expandedFilter : ''

  return {
    selectedFiltersLength,
    expandedFilterStyle,
  }
}
