import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { connectRange } from 'react-instantsearch-dom'
import debounce from 'lodash/debounce'
import dynamic from 'next/dynamic'

// Redux
import { useDispatch, useSelect } from 'store/index'
import {
  backgroundSearch,
  filterPrice,
  setSelectedMaxPrice,
  setSelectedMinPrice,
  setShouldApplyFilters,
  setShouldResetPriceRange,
  updateSearchFiltersString,
} from 'store/search'

import { setDesktopFilterOpen } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import style from './Price.module.scss'

import useClickOutSideFilters from '../useClickOutSideFilters'

import urlToSearchState from 'utils/search/urlToSearchState'

import CaretIcon from 'assets/icons/icon-caret.svg'
import SliderBackground from 'assets/images/price-graphicGrey.svg'

const Slider = dynamic(() => import('@material-ui/core/Slider'))

type PriceProps = {
  expanded?: boolean
  refine: ({ min, max }: { min?: number; max?: number }) => void
  currentRefinement: {
    min: number
    max: number
  }
}

const formatValue = (value: number) => {
  return value.toLocaleString()
}

const Price: React.FC<PriceProps> = ({ expanded, currentRefinement }) => {
  const router = useAugmentedRouter()

  const { minLOS_PriceAverages, maxLOS_PriceAverages } = useMemo(
    () => urlToSearchState(router.asPath),
    [router.asPath],
  )

  const selectedMinPrice = useSelect((state) => state.search.selectedMinPrice)
  const selectedMaxPrice = useSelect((state) => state.search.selectedMaxPrice)

  const searchFiltersMounted = useSelect(
    (state) => state.search.searchFiltersMounted,
  )

  const [stateMin, setStateMin] = useState(
    selectedMinPrice !== undefined
      ? selectedMinPrice
      : currentRefinement?.min
      ? currentRefinement?.min
      : minLOS_PriceAverages
      ? minLOS_PriceAverages
      : 0,
  )
  const [stateMax, setStateMax] = useState(
    selectedMaxPrice !== undefined
      ? selectedMaxPrice
      : currentRefinement?.max
      ? currentRefinement?.max
      : maxLOS_PriceAverages
      ? maxLOS_PriceAverages
      : 1000,
  )

  const [rangeValues, setRangeValues] = useState({
    min: stateMin,
    max: stateMax,
  })
  const [hasTotal, setHasTotal] = useState(
    selectedMinPrice ||
      selectedMaxPrice ||
      currentRefinement?.min ||
      currentRefinement?.max ||
      minLOS_PriceAverages ||
      maxLOS_PriceAverages
      ? true
      : false,
  )
  const [filterOpen, setFilterOpen] = useState(false)
  const numResults = useSelect((state) => state.search.numResults)
  const shouldResetPriceRange = useSelect(
    (state) => state.search.shouldResetPriceRange,
  )

  const priceRef = useRef<HTMLDivElement>(null)

  const appDispatch = useDispatch()

  const handleChange = useCallback((event, value) => {
    setHasTotal(false)
    setStateMin(value[0])
    setStateMax(value[1])
    setRangeValues({
      min: value[0],
      max: value[1],
    })
  }, [])

  const handleApply = useCallback(
    (maintainDesktopFilter: boolean) => {
      setFilterOpen(false)
      if (!maintainDesktopFilter) {
        appDispatch(setDesktopFilterOpen(false))
      }
      if (stateMin || stateMax < 1000) {
        setHasTotal(true)
      }
      appDispatch(setSelectedMinPrice(stateMin))
      appDispatch(setSelectedMaxPrice(stateMax))
      appDispatch(updateSearchFiltersString())
      appDispatch(setShouldApplyFilters(true))
    },
    [stateMin, stateMax, appDispatch],
  )

  const handleFinalChange = useCallback(
    (event, value) => {
      setHasTotal(false)
      setStateMin(value[0])
      setStateMax(value[1])
      setRangeValues({
        min: value[0],
        max: value[1],
      })
      appDispatch(
        filterPrice({
          minPrice: value[0],
          maxPrice: value[1],
        }),
      )
    },

    [appDispatch],
  )

  const debouncedSetRangeMin = useRef(
    debounce(
      (value: string) =>
        setRangeValues((values) => ({
          ...values,
          min: +value,
        })),
      1000,
    ),
  )

  const debouncedSetRangeMax = useRef(
    debounce(
      (value: string) =>
        setRangeValues((values) => ({
          ...values,
          max: +value,
        })),
      1000,
    ),
  )

  const debouncedFilterPrice = useRef(
    debounce(
      (min: number, max: number) =>
        appDispatch(
          filterPrice({
            minPrice: min,
            maxPrice: max,
          }),
        ),
      1000,
    ),
  )

  const handleInputChange = useCallback(
    (input: 'from' | 'to', value: string) => {
      let newRangeMin = stateMin
      let newRangeMax = stateMax

      switch (input) {
        case 'from':
          newRangeMin = +value
          setStateMin(+value)
          debouncedSetRangeMin.current(value)
          break
        case 'to':
          if (+value === 0) {
            newRangeMax = 1000
            setStateMax(1000)
            debouncedSetRangeMax.current('1000')
          } else {
            newRangeMax = +value
            setStateMax(+value)
            debouncedSetRangeMax.current(value)
          }

          break
        default:
          break
      }
      debouncedFilterPrice.current(newRangeMin, newRangeMax)
      setHasTotal(false)
    },
    [stateMin, stateMax],
  )

  const handleBlur = (
    input: 'from' | 'to',
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (
      !event.relatedTarget ||
      (event.relatedTarget as HTMLInputElement).className !==
        'dollarInput-control'
    ) {
      if (stateMin && stateMax) {
        const from = input === 'from'
        const to = input === 'to'
        const idx = from ? 0 : 1
        const newValues = [stateMin, stateMax]
        let newValue = to ? stateMax : stateMin
        if (newValue < 0) {
          newValue = 0
        }
        if (newValue > 1000) {
          newValue = 1000
        }

        if (from && +newValue >= stateMax) {
          newValues[idx] = stateMax - 1
        } else if (to && +newValue <= stateMin) {
          newValues[idx] = stateMin + 1
        } else {
          newValues[idx] = +newValue
        }

        setStateMin(newValues[0])
        setStateMax(newValues[1])
      }
    }
  }

  const handleClear = () => {
    setHasTotal(false)
    setStateMin(0)
    setStateMax(1000)
    setRangeValues({
      min: 0,
      max: 1000,
    })
    appDispatch(
      filterPrice({
        minPrice: undefined,
        maxPrice: undefined,
      }),
    )
    appDispatch(backgroundSearch())
  }

  useEffect(() => {
    if (shouldResetPriceRange) {
      setStateMin(0)
      setStateMax(1000)
      setRangeValues({
        min: 0,
        max: 1000,
      })
      appDispatch(setShouldResetPriceRange(false))
    }
  }, [appDispatch, shouldResetPriceRange])

  useClickOutSideFilters({
    ref: priceRef,
    onClickOutSide: handleApply,
    isOpen: filterOpen,
  })

  return (
    <div
      className={`${style.select} ${hasTotal ? style.hasTotal : ''} ${
        expanded ? style.expanded : ''
      }`}
      ref={priceRef}
    >
      {!expanded && (
        <div
          className={`${style.value} ${
            filterOpen ? style.focused : ''
          } filter-category-button`}
          data-testid="priceBtn"
          onClick={() => {
            if (filterOpen) {
              appDispatch(setDesktopFilterOpen(false))
            } else {
              appDispatch(setDesktopFilterOpen(true))
            }
            setFilterOpen((filterOpen) => !filterOpen)
          }}
        >
          {hasTotal && (stateMin || stateMax < 1000) ? (
            <b> {`$${formatValue(stateMin)}-${formatValue(stateMax)}`} </b>
          ) : (
            <span>Price</span>
          )}
          <CaretIcon
            className={`${style.caret} ${filterOpen ? style.focused : ''}`}
            height="7px"
            width="12px"
          />
        </div>
      )}
      <div
        className={`${style.options} ${
          filterOpen || expanded ? style.optionsOpen : null
        }`}
      >
        {expanded && <h3>Price</h3>}
        <div>
          <div className={style.rangeSlider}>
            <SliderBackground />
            <Slider
              className={style.slider}
              max={1000}
              min={0}
              onChange={handleChange}
              onChangeCommitted={handleFinalChange}
              value={[rangeValues.min, rangeValues.max]}
            />
          </div>
          <div className={style.priceLabels}>
            <span>Min per night</span>
            <span>Max per night</span>
          </div>
          <div className={style.priceInputs}>
            <div className={style.iconInput}>
              <i>$</i>
              <input
                className="dollarInput-control"
                data-testid="minPerNight"
                min={0}
                name="minPriceInput"
                onBlur={(e) => handleBlur('from', e)}
                onChange={(e) => handleInputChange('from', e.target.value)}
                placeholder={'0'}
                type="number"
                value={stateMin > 0 ? stateMin : ''}
              />
            </div>
            <span>to</span>
            <div className={style.iconInput}>
              <i>$</i>
              <input
                className={`dollarInput-control ${
                  stateMax > 1000 ? style.boldPlaceholder : ''
                }`}
                data-testid="maxPerNight"
                onBlur={(e) => handleBlur('to', e)}
                onChange={(e) => handleInputChange('to', e.target.value)}
                placeholder={'1000+'}
                type="number"
                value={stateMax < 1000 ? stateMax : ''}
              />
            </div>
          </div>
        </div>
        {!expanded && (
          <footer className={style.footer}>
            <button
              className={`${style.btn} ${style.clearBtn}`}
              data-testid="priceClearBtn"
              disabled={
                searchFiltersMounted && !stateMin && stateMax >= 1000
                  ? true
                  : false
              }
              onClick={() => handleClear()}
            >
              Clear
            </button>
            <button
              className="btn-primary"
              data-testid="viewFilterResults"
              onClick={() => handleApply(false)}
            >
              {`View ${numResults} ${numResults === 1 ? 'Result' : 'Results'}`}
            </button>
          </footer>
        )}
      </div>
    </div>
  )
}

export default connectRange(Price)
