import React, { useRef, useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { shallowEqual } from 'react-redux'
import type { HitsProvided } from 'react-instantsearch-core'

import { useDispatch, useSelect } from 'store/index'

import { setActiveMarker } from 'reducers/searchData'
import { setMobileFiltersOpen } from 'reducers/uiState'

import AlgoliaMarker from './AlgoliaMarker'
import CustomGeoSearch from './CustomGeoSearch'
import style from './MapAlgolia.module.scss'

import FiltersIcon from 'assets/icons/icon-moreFilters.svg'
import CloseIcon from 'assets/icons/icon-close.svg'

import type { AlgoliaListing } from 'types/externalData'

const defaultZoom = 4
const defaultPosition = { lat: 37.09024, lng: -95.712891 }

type MapAlgoliaProps = {
  onMapViewClick: () => void
  cardRef: React.RefObject<HTMLDivElement>
} & HitsProvided<AlgoliaListing>

// hits provided by custom withHits connector
const MapAlgolia = React.memo(
  ({ hits, onMapViewClick, cardRef }: MapAlgoliaProps) => {
    const mapPosition = useSelect((state) => state.search.latLng, shallowEqual)
    const activeView = useSelect((state) => state.uiState.activeView)
    const mapRef = useRef<HTMLDivElement>(null)
    const mapInstance = useRef<google.maps.Map<HTMLDivElement> | null>(null)

    const appDispatch = useDispatch()

    const handleMapViewClick = () => {
      onMapViewClick()
      appDispatch(setActiveMarker(''))
    }

    const renderHits = useCallback(
      () =>
        hits.map((hit) => (
          <AlgoliaMarker cardRef={cardRef} hit={hit} key={hit.objectID} />
        )),
      [cardRef, hits],
    )

    return (
      <>
        <button
          className={`${activeView === 'map' ? style.fabMap : style.mapHidden}`}
          onClick={handleMapViewClick}
        >
          <CloseIcon width={11} />
        </button>
        <button
          className={`${
            activeView === 'map' ? style.mapFilters : style.mapHidden
          }`}
          onClick={() => appDispatch(setMobileFiltersOpen(true))}
        >
          <FiltersIcon />
          <span>Filters</span>
        </button>
        <div
          className={`
        ${style.map}
        ${activeView === 'grid' ? style.mapHidden : ''}
      `}
          id="map"
        >
          <CustomGeoSearch
            initialPosition={mapPosition ?? defaultPosition}
            initialZoom={defaultZoom}
            mapInstanceRef={mapInstance}
            mapRef={mapRef}
            maxZoom={18}
          >
            {renderHits}
          </CustomGeoSearch>
        </div>
      </>
    )
  },
  isEqual,
)

MapAlgolia.displayName = 'MapAlgolia'

export default MapAlgolia
