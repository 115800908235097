import React, { useMemo } from 'react'

import styles from './CancellationPolicyOnSearch.module.scss'

import { getDatesDiffInDays, getUntilDateFormats } from 'utils/Dates'

import PaymentInfoIcon from 'assets/icons/icon-paymentInfo.svg'

const MAX_CANCELLATION_PERIOD = 16
const MMIN_CANCELLATION_PERIOD = 3

type CancellationOnSearchProps = {
  checkInDate?: string
  checkOutDate?: string
}

const CancellationOnSearch: React.FC<CancellationOnSearchProps> = ({
  checkInDate,
  checkOutDate,
}) => {
  const message = useMemo(() => {
    if (checkInDate) {
      const diffDays = getDatesDiffInDays(new Date(), new Date(checkInDate))

      if (diffDays > MAX_CANCELLATION_PERIOD) {
        const [untilDateShortFormat, untilDateLongFormat] =
          getUntilDateFormats(checkInDate)

        return (
          <span className={styles.cancellationPolicyText}>
            Book worry free! With your travel dates you’ve got until{' '}
            <strong>{untilDateLongFormat}</strong> to cancel for a full refund
            if you change your mind.
          </span>
        )
      } else if (
        diffDays <= MAX_CANCELLATION_PERIOD &&
        diffDays > MMIN_CANCELLATION_PERIOD
      ) {
        return (
          <span className={styles.cancellationPolicyText}>
            Book worry free! With your travel dates you’ve got{' '}
            <strong>48 hours after booking</strong> to cancel for a full refund
            if you change your mind.
          </span>
        )
      } else if (diffDays <= MMIN_CANCELLATION_PERIOD) {
        return undefined
      }
    }

    return (
      <span className={styles.cancellationPolicyText}>
        Evolve provides flexibility for cancellations and refunds. Add dates for
        more detailed cancellation policies.
      </span>
    )
  }, [checkInDate])

  if (message) {
    return (
      <div className={styles.cancellationPolicyContainer}>
        <div className={styles.icon__wrapper}>
          <PaymentInfoIcon height={19} width={28} />
        </div>
        {message}
      </div>
    )
  }

  return null
}

export default CancellationOnSearch
