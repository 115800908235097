import React, { useState, useRef, useCallback, useMemo } from 'react'

import { useDispatch, useSelect } from 'store/index'
import {
  backgroundSearch,
  clearBedsAndBath,
  setShouldApplyFilters,
} from 'store/search'

import { setDesktopFilterOpen } from 'reducers/uiState'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import style from './BedsAndBath.module.scss'

import useClickOutSideFilters from '../useClickOutSideFilters'
import MinRangePicker from '../MinRangePicker/MinRangePicker'

import urlToSearchState from 'utils/search/urlToSearchState'

import CaretIcon from 'assets/icons/icon-caret.svg'

type BedsAndBathProps = {
  cachedFacetsStats?: {
    min: number
    max: number
    avg: number
    sum: number
  }
}

type ButtonLabeling = {
  beds: string
  bedrooms: string
  bathrooms: string
  default: string
}

const BedsAndBath: React.FC<BedsAndBathProps> = () => {
  const router = useAugmentedRouter()

  const [filterOpen, setFilterOpen] = useState(false)

  const numResults = useSelect((state) => state.search.numResults)
  const selectedMinBeds = useSelect((state) => state.search.selectedMinBeds)
  const selectedMinBedrooms = useSelect(
    (state) => state.search.selectedMinBedrooms,
  )
  const selectedMinBathrooms = useSelect(
    (state) => state.search.selectedMinBathrooms,
  )
  const searchFiltersMounted = useSelect(
    (state) => state.search.searchFiltersMounted,
  )

  const minBeds =
    selectedMinBeds !== undefined
      ? selectedMinBeds
      : urlToSearchState(router.asPath).minBeds ?? 0
  const minBedrooms =
    selectedMinBedrooms !== undefined
      ? selectedMinBedrooms
      : urlToSearchState(router.asPath).minBedrooms ?? 0
  const minBathrooms =
    selectedMinBathrooms !== undefined
      ? selectedMinBathrooms
      : urlToSearchState(router.asPath).minBathrooms ?? 0

  const buttonLabelMapping = useMemo<Partial<ButtonLabeling>>(() => {
    if (!minBeds && !minBathrooms && !minBedrooms)
      return { default: 'Beds and Bath' }

    return {
      beds: minBeds ? `Bds (<b>${minBeds}</b>)` : '',
      bedrooms: minBedrooms ? `Bdrm (<b>${minBedrooms}</b>)` : '',
      bathrooms: minBathrooms ? `Bath (<b>${minBathrooms}</b>)` : '',
    }
  }, [minBathrooms, minBedrooms, minBeds])

  const elRef = useRef<HTMLDivElement>(null)

  const appDispatch = useDispatch()

  const handleApply = useCallback(
    (maintainDesktopFilter: boolean) => {
      appDispatch(setShouldApplyFilters(true))
      setFilterOpen(false)
      if (!maintainDesktopFilter) {
        appDispatch(setDesktopFilterOpen(false))
      }
    },
    [appDispatch],
  )

  const handleClear = () => {
    appDispatch(clearBedsAndBath())
    appDispatch(backgroundSearch())
  }

  useClickOutSideFilters({
    ref: elRef,
    isOpen: filterOpen,
    onClickOutSide: handleApply,
  })

  return (
    <div
      className={`${style.select} ${true ? style.hasTotal : ''}`}
      ref={elRef}
    >
      <div
        className={`${style.value} ${
          filterOpen ? style.focused : ''
        } filter-category-button`}
        data-testid="bedsAndBathBtn"
        onClick={() => {
          if (!filterOpen) {
            appDispatch(setDesktopFilterOpen(true))
          } else {
            appDispatch(setDesktopFilterOpen(false))
          }
          setFilterOpen((filterOpen) => !filterOpen)
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: Object.values(buttonLabelMapping)
              .filter(Boolean)
              .join(', '),
          }}
        ></span>
        <CaretIcon
          className={`${style.caret} ${filterOpen ? style.focused : ''}`}
          height="7px"
          width="12px"
        />
      </div>

      <div className={filterOpen ? style.options : style.hidden}>
        <MinRangePicker attribute="Bedrooms" />
        <MinRangePicker attribute="Total Beds" />
        <MinRangePicker attribute="Bathrooms" />
        <footer className={style.footer}>
          <button
            className={`${style.btn} ${style.clearBtn}`}
            disabled={
              searchFiltersMounted && !minBeds && !minBathrooms && !minBedrooms
            }
            onClick={() => handleClear()}
          >
            Clear
          </button>
          <button className="btn-primary" onClick={() => handleApply(false)}>
            {`View ${numResults} ${numResults === 1 ? 'Result' : 'Results'}`}
          </button>
        </footer>
      </div>
    </div>
  )
}

export default BedsAndBath
